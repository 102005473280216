import { useState } from 'react';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { KeywordResponse } from '../../../types';

export const DomainBatchModel = () => {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, bulkKeywords: false });
  const[country, setCountry] = useState<string>(COUNTRY_CODE_ES)
  const[bulkKeywordsData, setBulkKeywordsData] = useState<KeywordResponse>()
  const[filter, setFilter] = useState(new Map())

  return {
    isLoading, setIsLoading,
    country, setCountry,
    bulkKeywordsData, setBulkKeywordsData,
    filter, setFilter
  }
}