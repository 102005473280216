import { BillingsResponse } from '../types/common.type';
import { ColumnsReturnType } from '../types';
import { GridColDef } from '@mui/x-data-grid';

// COMPONENTS
import * as COLUMN from './TableConstants';
import { useUserContext } from '../context/UserContext';
import { filterContainsKeywordsOperators } from './Utils';

export const getBlur = () => {
  const { blur } = useUserContext()
  return blur
}


// ******************** KEYWORDS ********************
export const COLUMNS_KEYWORD_ANALYSIS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', country: options.country, flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume'  }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false }),
  COLUMN.GENERIC(t, { field: 'serp_info.item_types', minWidth: 105, sortable: false }),
  COLUMN.GENERIC(t, { field: 'keyword_info.history', flex: 0.24, minWidth: 160, sortable: false })
];

export const COLUMNS_KEYWORD_BATCH: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', country: options.country, flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false }),
  COLUMN.GENERIC(t, { field: 'serp_info.item_types', sortable: false }),
  COLUMN.GENERIC(t, { field: 'keyword_info.history', flex: 0.24, minWidth: 160, sortable: false })
];

export const COLUMNS_KEYWORD_SERP_OVERVIEW: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'rank_absolute', align: 'center', headerAlign: 'center' }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.domain', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'etv', flex: 0.2 }),
  COLUMN.GENERIC(t, { field: 'traffic_cost', flex: 0.2 })
];


// ******************** DOMAINS ********************
export const COLUMNS_DOMAIN_KEYWORDS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'serp_info.serp.keyword', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left'  }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.rank_absolute' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.etv' }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.traffic_cost' })
];

export const COLUMNS_DOMAIN_KEYWORD_INTENT: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'search_intent_info.intentWithDescription', flex: 1, type: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'distribution' }),
  COLUMN.GENERIC(t, { field: 'urls' })
];

export const COLUMNS_DOMAIN_COMPETITORS_KEYWORDS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'main_domain', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keywords_count' }),
  COLUMN.GENERIC(t, { field: 'unique_urls_count' }),
  COLUMN.GENERIC(t, { field: 'total_traffic' }),
  COLUMN.GENERIC(t, { field: 'total_traffic_cost' })
];

export const COLUMNS_DOMAIN_BULK_ANALYSIS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'main_domain', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keywords_count' }),
  COLUMN.GENERIC(t, { field: 'unique_urls_count' }),
  COLUMN.GENERIC(t, { field: 'search_intent', minWidth: 180, sortable: false }),
  COLUMN.GENERIC(t, { field: 'rank_ranges', flex: 0.6, minWidth: 340, sortable: false }),
  COLUMN.GENERIC(t, { field: 'total_traffic' }),
  COLUMN.GENERIC(t, { field: 'total_traffic_cost' })
];

export const COLUMNS_DOMAIN_POSITIONS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'domain_position.keyword', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.rank_absolute' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.etv' }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.traffic_cost' })
];

export const COLUMNS_DOMAIN_BACKLINKS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'page_from_title', flex: 0.4, minWidth: 280, type: 'string', align: 'left', headerAlign: 'left', sortable: false  }),
  COLUMN.GENERIC(t, { field: 'anchor', flex: 0.4, minWidth: 280, type: 'string', align: 'left', headerAlign: 'left', sortable: false  }),
  COLUMN.GENERIC(t, { field: 'externalLinks' }),
  COLUMN.GENERIC(t, { field: 'internalLinks' }),
  COLUMN.GENERIC(t, { field: 'backlinkSpamScore' }),
  COLUMN.GENERIC(t, { field: 'createdAt' , sortable: false, minWidth: 110 }),
  COLUMN.GENERIC(t, { field: 'updatedAt', sortable: false, minWidth: 110  }),
];

export const COLUMNS_DOMAIN_CONTENT_GAP: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  ...options.domains.map((domain: string, index: number): GridColDef => (
    COLUMN.GENERIC(t, { field: `domain_${index}`, headerName: domain || 'Domain', options: options, flex: 0.4 })
  )),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'serp_info.se_results_count' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false })
];

export const COLUMNS_DOMAIN_BEST_PAGES: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'serp_info.serp.keyword', code: options.code, flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left'  }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.etv' }),
  COLUMN.GENERIC(t, { field: 'serp_info.serp.0.traffic_cost' })
];


// ******************** IDEAS ********************
export const COLUMNS_NICHE_IDEAS_KEYWORDS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.search_volume' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.cpc' }),
  COLUMN.GENERIC(t, { field: 'extra.keyword_difficulty' }),
  COLUMN.GENERIC(t, { field: 'keyword_info.competition' }),
  COLUMN.GENERIC(t, { field: 'search_intent_info.intent', sortable: false  }),
  COLUMN.GENERIC(t, { field: 'serp_info.item_types', sortable: false }),
  COLUMN.GENERIC(t, { field: 'keyword_info.history', flex: 0.24, minWidth: 160, sortable: false })
];

export const COLUMNS_DOMAIN_IDEAS_OVERVIEW: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'domain', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'keywords' }),
  COLUMN.GENERIC(t, { field: 'urls' }),
  COLUMN.GENERIC(t, { field: 'total_traffic' }),
  COLUMN.GENERIC(t, { field: 'trafficCost' })
];


// ******************** PROJECTS ********************
export const COLUMNS_PROJECT_CANNIBALIZATION: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', flex: 0.4, minWidth: 400, type: 'string', align: 'left', headerAlign: 'left'  }),
  COLUMN.GENERIC(t, { field: 'cannibalization_urls', flex: 1, minWidth: 600, type: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'cannibalization_clicks', sortable: false }),
  COLUMN.GENERIC(t, { field: 'cannibalization_impressions', sortable: false }),
  COLUMN.GENERIC(t, { field: 'cannibalization_positions', sortable: false }),
];

export const COLUMNS_PROJECT_THIN_CONTENT: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'url', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left'   }),
  COLUMN.GENERIC(t, { field: 'ctr' }),
  COLUMN.GENERIC(t, { field: 'clicks' }),
  COLUMN.GENERIC(t, { field: 'impressions' }),
  COLUMN.GENERIC(t, { field: 'average_position' }),
  COLUMN.GENERIC(t, { field: 'thinContent_problems', minWidth: 220, sortable: false })
];

export const COLUMNS_PROJECT_OPPORTUNITY_KEYWORD: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'keyword', flex: 1, minWidth: 240, type: 'string', align: 'left', headerAlign: 'left' }),
  COLUMN.GENERIC(t, { field: 'ctr' }),
  COLUMN.GENERIC(t, { field: 'clicks' }),
  COLUMN.GENERIC(t, { field: 'impressions' }),
  COLUMN.GENERIC(t, { field: 'position' }),
  COLUMN.GENERIC(t, { field: 'contains_keyword', minWidth: 400, type: 'string', filterOperators: filterContainsKeywordsOperators })
];

// ******************** PROJECTS ADMIN ********************
export const COLUMNS_PROJECTS_ADMIN_MANAGE = (t, options): GridColDef[] => [
  COLUMN.GENERIC(t, { field: 'project_property', flex: 0.4, minWidth: 500, type: 'string', align: 'left', headerAlign: 'left', sortable: false, blur: getBlur() }),
  COLUMN.GENERIC(t, { field: 'project_accountInfo', flex: 1, minWidth: 600, type: 'string', align: 'left', headerAlign: 'left', sortable: false, blur: getBlur() })
];


// ******************** ACCOUNT ********************
export const COLUMNS_ACCOUNT_CONNECTIONS: ColumnsReturnType = (t, options) => [
  COLUMN.GENERIC(t, { field: 'project_account', flex: 1, type: 'string', align: 'left', headerAlign: 'left', sortable: false })
];


// ******************** CRM ********************
export const COLUMNS_CRM_INVOICES: ColumnsReturnType<BillingsResponse> = (t, options) => [
  COLUMN.GENERIC(t, { field: 'invoiceStatus',  flex: 1, ype: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'invoiceDescription', flex: 1, type: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'invoiceCreated', minWidth: 140, type: 'string', align: 'left', headerAlign: 'left', sortable: false }),
  COLUMN.GENERIC(t, { field: 'invoiceAmountDue', minWidth: 140, sortable: false }),
  COLUMN.GENERIC(t, { field: 'invoiceDownload', minWidth: 180, sortable: false })
];
