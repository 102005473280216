import './index.scss';
import './i18n';
import './script';
import * as ReactDOM from 'react-dom/client';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { UserProvider } from './context/UserContext';
import { PlanProvider } from './context/PlanContext';
import { ColorModeProvider } from './context/ColorModeContext';
import { SearchProvider } from './context/SearchCacheContext';
import { ROUTES } from './utils/Constants';
import { ErrorBoundary } from 'react-error-boundary';
import CustomErrorBoundary from './components/Error/CustomErrorBoundary';

const isBuyPlanRoute = () => new URLSearchParams(window.location.search).get('state')?.includes('buy-plan');

const onRedirectCallback = (appState?: AppState) => {
  if (isBuyPlanRoute()) {
    window.history.replaceState({}, document.title, ROUTES.BUY_PLAN_USER);
  } else {
    const url = appState?.returnTo || window.location.pathname;
    window.history.replaceState({}, document.title, url);
  }
};

const renderApp = () => {
  return (
    <ErrorBoundary FallbackComponent={CustomErrorBoundary}>
      <Auth0Provider
        useRefreshTokens
        useRefreshTokensFallback
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
        domain="dev-7iczrz4kq8jjusg8.eu.auth0.com"
        clientId="F9jCNUw5Z00TV60LQyciYVB3opkYedkQ"
        authorizationParams={{
          scope: 'profile offline_access',
          redirect_uri: window.location.origin,
          audience: 'https://dev-7iczrz4kq8jjusg8.eu.auth0.com/api/v2/'
        }}
      >
        <ColorModeProvider>
          <UserProvider>
            { /* <KeywordsCacheProvider>  */ }
              <SearchProvider>
                <PlanProvider>
                  { /* <DomainsCacheProvider>  */ }
                    { /* <KeywordsIdeasCacheProvider> */ }
                      <App />
                    { /* </KeywordsIdeasCacheProvider> */ }
                  { /*  </DomainsCacheProvider> */ }
                </PlanProvider>
              </SearchProvider>
            { /* </KeywordsCacheProvider> */ }
          </UserProvider>
        </ColorModeProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(renderApp());