import { IconButton, MenuItem, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GlobalMetricToggle from '../../../components/Charts/SearchConsoleChart/GlobalMetricToggle/GlobalMetricToggle';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import ColumnSelector from '../../../components/Selectors/ColumnSelector/ColumnSelector';
import DateRangeSelectorView from '../../../components/Selectors/DateRangeSelector/DateRangeSelectorView';
import { useTranslation } from 'react-i18next';
import ToggleCustomDots from '../../../components/Charts/SearchConsoleChart/ToggleCustomDots/ToggleCustomDots';
import { extractRootDomain } from '../../../utils/Utils';
import Selector from '../../../components/Selector/Selector';
import SelectorView from '../../../components/Projects/ProjectSelector/ProjectSelectorView';


const ProjectsPerformanceFilterView = ({ model, controller }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid>
        <Tooltip title={model.blur ? t('domains_hidden') : t('domains_visible')}>
          <IconButton sx={{ padding: 0 }} onClick={model.toggleBlur} aria-label="toggle blur">
            {model.blur ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <ToggleCustomDots active={model.showCustomDots} onClick={() => {
          model.setShowCustomDots(!model.showCustomDots);
        }} />
      </Grid>

      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <GlobalMetricToggle metrics={model.globalMetrics} onToggle={controller.handleGlobalMetricToggle} />
      </Grid>

      {model.customDomainData &&
        <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
          <ColumnSelector columns={model.columns} onChange={model.setColumns} />
        </Grid>
      }

      <Grid>
        <DateRangeSelectorView onChange={model.setDateRange} />
      </Grid>

      {model.project &&
        <Grid>
          <SelectorView label={t('project')} project={model.project || ''} setProject={model.setProject} itemList={model.dataProject} blur={model.blur} />
        </Grid>
      }

    </Grid>
  );
};

export default ProjectsPerformanceFilterView;