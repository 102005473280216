import { useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid-premium';

const useSortModel = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  return {
    sortModel,
    setSortModel
  };
};

export default useSortModel;