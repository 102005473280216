import './Search.scss';
import { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, InputBase } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CountryWithCodeSelector from '../CountryWithCodeSelector/CountryWithCodeSelector';
import { useSearchContext } from '../../context/SearchCacheContext';
import { useSearchParams } from 'react-router-dom';


interface Props {
  loading?: boolean;
  placeholder?: string;
  onSearch?: (values: boolean) => void;
  onChange?: (values: string) => void;
  onCountry?: (country: string) => void;
  lines?: number;
  maxLines?: number;
}

const Search: FC<Props> = ({ loading, onChange, onSearch, onCountry, placeholder, lines, maxLines }) => {
  const { t } = useTranslation();

  // Hooks
  const { inputValue, setInputValue, country, setCountry } = useSearchContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const manualUpdateRef = useRef(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  }

  const onBtnClearClick = () => {
    setInputValue('');
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (lines===undefined && event.key === 'Enter') {
      onBtnSearch(true);
    }

    if (maxLines !== undefined) {
      const numberOfRows = event.currentTarget.value.split('\n').length;
      if (numberOfRows >= maxLines) {
        event.preventDefault();
      }
    }
  }

  const handleCountryChange = (country: string) => {
    setCountry(country);
  };

  const onBtnSearch = useCallback((clearParams?: boolean) => {
    //if(!inputValue){
      //return
    //}
    onSearch(clearParams);
  }, [onSearch]);


  useEffect(() => {
    return () => resetState();
  }, []);

  const resetState = () => {
    onSearch = undefined
    setInputValue(undefined)
  }

  useEffect(() => {

    const keywords = searchParams.get('q');
    const db = searchParams.get('country');

    if (db) {
      setCountry(db);
    }

    if ((keywords && keywords !== inputValue) || searchParams.size>0) {
      manualUpdateRef.current = true;
      setInputValue(keywords);
      onSearch(false)
    } else{
      setInputValue('')
    }

  }, [searchParams]);

  /*
  useEffect(() => {
    if(!inputValue || searchParams.size===0){
      setInputValue('')
      return
    }

    onSearch(false)
  }, [searchParams]);
*/

  /*
  useEffect(() => {
    return () => resetState();
  }, []);

  const resetState = () => {
    onSearch = undefined
  }

  useEffect(() => {
    const keywords = searchParams.get('q');
    if ((keywords && keywords !== inputValue) || searchParams.size>0) {
      manualUpdateRef.current = true;
      setInputValue(keywords);
    } else{
      setInputValue('')
    }

    const db = searchParams.get('country');
    if (db) {
      setCountry(db);
    }

  }, [searchParams]);

  useEffect(() => {
    if (manualUpdateRef.current && inputValue) {
      onBtnSearch(false); // Call onBtnSearch after the manual update
      manualUpdateRef.current = false; // Reset the flag after the update
    }
  }, [inputValue]);


  const onBtnSearch = useCallback((clearParams?: boolean) => {
    if(clearParams!==false){
      setSearchParams({}, { replace: true });
    }

    if (onSearch) {
      onSearch(clearParams);
    }
  }, [inputValue, onSearch]);

  const onBtnClearClick = useCallback(() => {
    setInputValue('');
  }, [setInputValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
    setInputValue(value);
  };

  const handleCountryChange = (location: string) => {
    setCountry(location);
    if (onCountry) {
      onCountry(location);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onBtnSearch(true);
    }

    if (maxLines !== undefined) {
      const numberOfRows = event.currentTarget.value.split('\n').length;
      if (numberOfRows >= maxLines) {
        event.preventDefault();
      }
    }
  };
   */

  return (
    <div className="search">
      {onCountry && (
        <div className="countrySelector">
          <CountryWithCodeSelector onChange={(e) => handleCountryChange(e.country)} />
        </div>
      )}
      <div className="input">
        <InputBase
          disabled={loading}
          sx={{ ml: 1, flex: 1 }}
          value={inputValue}
          placeholder={placeholder ?? t('button.search')}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          multiline={lines > 1}
          rows={lines > 1 ? lines : 1}
        />
        <IconButton
          type="button"
          aria-label="clear"
          title={t('button.clear')}
          onClick={onBtnClearClick}
          sx={{ p: '5px', borderRadius: 0 }}
          style={{ opacity: inputValue ? 1 : 0 }}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <IconButton
        type="button"
        sx={{ height: "100%", width: '60px' }}
        disabled={loading}
        aria-label="search"
        className="btnSearch"
        onClick={onBtnSearch}
        title={t('button.search')}
      >
      {loading ? (
        <CircularProgress className="progress" size={20} style={{ opacity: loading ? 1 : 0 }} />
      ) : (
        <SearchIcon sx={{ height: '30px' }} style={{ opacity: loading ? 0 : 1 }} />
      )}
      </IconButton>
    </div>
  );
};

export default Search;
