import gsap from 'gsap'
import { useTranslation } from 'react-i18next'
import { TextPlugin } from 'gsap/dist/TextPlugin'
import { Container, Typography } from '@mui/material'
import './SEOAnimation.scss'
import { useEffect, useRef } from 'react'

gsap.registerPlugin(TextPlugin)

const SEOAnimation = () => {
  const animationRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const tl = gsap.timeline()

    tl.to('.text-box', { text: t('routes.projects'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.ideas'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.niche-ideas'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.content-gap'), duration: 2, delay: 3 })
    tl.repeat(-1)
  }, [])

  return (
    <Container maxWidth='lg' className='animation-container'>
      <div className='seo-animation' ref={animationRef}>
        <div className='chart'>
          <div className='chart-bar' />
        </div>
        <Typography className='text-box'>{t('discover-the-possibilities-of-rankerfy')}</Typography>
      </div>
    </Container>
  )
}

export default SEOAnimation
