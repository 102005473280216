import { formatDate } from '../../utils/Utils';

interface Props {
  value: number
}

const InvoiceDate = (value: Props) => {

  const date = new Date(value.value * 1000);
  return formatDate(date.toString())

}

export default InvoiceDate