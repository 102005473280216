import RestDomainController from '../../../hooks/RestDomainController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { parseStringToArray } from '../../../utils/Utils';

export const DomainBatchController = (model) => {

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country } = useSearchContext();

  // functions

  // Applu params when any of the filter changes
  const handleFilterUpdate = useCallback((name, from, to, action) => {
    model.setFilter((prevItems) => {
      const newItems = new Map([...prevItems]);

      // Initialize an object to store filter values directly
      const payload = {};

      if (action === "delete") {
        // Remove the filter keys from the Map
        newItems.delete(`${name}From`);
        newItems.delete(`${name}To`);
        newItems.delete(`${name}`);
      } else if (name === 'intention') {
        newItems.set(`${name}`, action);
      } else {
        if (from) newItems.set(`${name}From`, from); else newItems.delete(`${name}From`)
        if (to) newItems.set(`${name}To`, to); else newItems.delete(`${name}To`)
      }

      // Convert the Map into an object with only raw values
      newItems.forEach((value, key:any) => {
        payload[key] = value; // Store only the value, no extra object
      });

      // Update model state (if needed)
      model.setFilter(newItems);

      return new Map(newItems); // Ensure React detects changes
    });
  },[model.filter])

  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { domain: parseStringToArray(inputValue), country: country };

    model.filter.forEach((value, key) => {
      params[key] = value;
    });

    model.setIsLoading((prev) => ({ ...prev, search: true }))
    model.setIsLoading((prev) => ({ ...prev, bulkKeywords: true }))
    params['limit'] = 10
    rest({ params, endpoint: 'getBulkOverview'})
      .then((data) => {
        model.setBulkKeywordsData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, bulkKeywords: false }))
        model.setIsLoading((prev) => ({ ...prev, search: false }))
      })

  }, [inputValue, country, model.keywordsData, model.keywordsOverviewData, model.searchIntentData, model.filter]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }


  return {
    handleSearchBtn,
    handleCountryUpdate,
    handleFilterUpdate
  }
}