import { useState } from 'react';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { KeywordResponse } from '../../../types';
import usePaginationModel from '../../../hooks/usePaginationModel';
import useSortModel from '../../../hooks/useSortModel';

export const ContentGapModel = ()=> {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, keywords: false });
  const[country, setCountry] = useState<string>(COUNTRY_CODE_ES)
  const[keywordsData, setKeywordsData] = useState<KeywordResponse>()
  const[filter, setFilter] = useState(new Map())
  const[domains, setDomains] = useState<string[]>(['']);
  const[modeFilter, setModeFilter] = useState('common');

  return {
    isLoading, setIsLoading,
    country, setCountry,
    keywordsData, setKeywordsData,
    filter, setFilter,
    domains, setDomains,
    modeFilter, setModeFilter
  }
}