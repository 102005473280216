import './styles.scss';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
 data: Array<{
   key: number;
   name: string;
   className: string;
   value: number;
   distribution: number;
 }>,
  dataFetched: boolean
};

const ProgressBarMultiple = ({ data, dataFetched }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="progress-bar">
      {
        data.map((item) => {
          const value = dataFetched ? item.distribution : 25;
          const className = dataFetched ? item.className : 'noData';

          return <Tooltip arrow followCursor title={<div>{t(`intent.${item.name}`)}</div>}>
            <span
              key={item.key}
              className={`progress-item ${className}`}
              style={{ width: value * 7.5}}
            />
          </Tooltip>
        })

      }
    </div>
  );
};

export default ProgressBarMultiple;