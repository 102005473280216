import RestDomainController from '../../../hooks/RestDomainController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/Constants';
import useExportData from '../../../hooks/useExportData';
import { useTranslation } from 'react-i18next';

export const DomainGeneralController = (model) => {
  const { t } = useTranslation();

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country, setCountry } = useSearchContext();
  const navigate = useNavigate();

  // functions


  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { domain: inputValue, country: country, limit: 10 };

    model.setIsLoading((prev) => ({ ...prev, search: true }))

    model.setIsLoading((prev) => ({ ...prev, domainOverview: true }))
    const domainOverviewPromise = rest({ params, endpoint: 'getDomainOverview'})
      .then((data) => {
        model.setKeywordsOverviewData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, domainOverview: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, ranges: true }))
    const rangesPromise = rest({ params, endpoint: 'getRankRanges'})
      .then((data) => {
        model.setRankRangesData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, ranges: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, searchIntent: true }))
    const searchIntentPromise = rest({ params, endpoint: 'getSearchIntents'})
      .then((data) => {
        model.setSearchIntentData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, searchIntent: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, bestPages: true }))
    const bestPagesPromise = rest({ params, endpoint: 'getBestDomainPages'})
      .then((data) => {
        model.setBestPages(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, bestPages: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, competitors: true }))
    const competitorsPromise = rest({ params, endpoint: 'getCompetitors'})
      .then((data) => {
        model.setCompetitorsData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, competitors: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, topCountriesByKeyword: true }))
    const topCountriesByKeyword = rest({ params, endpoint: 'getTopCountriesByKeyword'})
      .then((data) => {
        model.setTopCountriesByKeyword(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, topCountriesByKeyword: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, topCountriesByTraffic: true }))
    const topCountriesByTraffic = rest({ params, endpoint: 'getTopCountriesByTraffic'})
      .then((data) => {
        model.setTopCountriesByTraffic(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, topCountriesByTraffic: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, domain: true }))
    params['limit'] = 10
    const domainPromise = rest({ params, endpoint: 'getKeywords'})
      .then((data) => {
        model.setDomainData(data.keywords);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, domain: false }))
      })

    // wait until all promises finished to restore search loading
    Promise.allSettled([domainOverviewPromise, rangesPromise, searchIntentPromise, bestPagesPromise, competitorsPromise, domainPromise, topCountriesByKeyword, topCountriesByTraffic]).then(() => {
      model.setIsLoading((prev) => ({ ...prev, search: false }))
    });

  }, [inputValue, country, model.keywordsData, model.keywordsOverviewData, model.searchIntentData]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }


  const redirectToKeywords = useCallback(() => {
    navigate(`${ROUTES.DOMAIN_KEYWORDS}?q=${inputValue}&country=${country}`);
  }, [location, navigate, inputValue, country, model.endpoints]);

  const redirectToCompetitors = useCallback(() => {
    navigate(`${ROUTES.DOMAIN_COMPETITORS}?q=${inputValue}&country=${country}`);
  }, [location, navigate, inputValue, country, model.endpoints]);

  // Export to excel
  const { isLoading, fetchExportDomainKeywords} = useExportData();
  const handleExportBtn = async () => {
    model.setIsLoading({ export: true })
    const params = { domain: inputValue, country: country };
    await fetchExportDomainKeywords({ ...params, title: t('exportDomainsPositions')  });
    model.setIsLoading({ export: false })
  }

  const handleOnClickCardListItem = (e) => {
    if(!inputValue){
      return
    }
    const location = e.currentTarget.children.item('flag').children.item('img').alt
    setCountry(location)
    navigate(`${ROUTES.DOMAIN_VIEW}?q=${inputValue}&country=${location}`);
  };


  return {
    handleSearchBtn,
    handleCountryUpdate,
    redirectToKeywords, redirectToCompetitors,
    handleExportBtn,
    handleOnClickCardListItem
  }
}