import '../ProjectProperty.scss'
import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { extractRootDomain } from '../../../utils/Utils';
import { ProjectInterface } from '../../../interfaces/ProjectInterface';
import * as React from 'react';

interface Props {
  label: string,
  project?: ProjectInterface,
  itemList: Array<ProjectInterface>
  setProject: Dispatch<SetStateAction<ProjectInterface>>
  blur?: boolean
}

const Selector: FC<PropsWithChildren<Props>> = ({ label, project, setProject, itemList, blur, ...props }) => {
  return (
    <Select variant={'outlined'} value={project.property || ''}
            onChange={(event) => {
              const project = event.target.value;
              const selectedItem = itemList.find((item) => item.property === project);
              if (selectedItem) {
                setProject(selectedItem);
              }
            }}
            displayEmpty
            fullWidth
            className={'projectSelector'}
    >
      {/* Placeholder */}
      <MenuItem value="" disabled style={{ display: 'none' }} >{label}</MenuItem>

      debugger
      {itemList ? (
        itemList
          .sort((a, b) => extractRootDomain(a.property).localeCompare(extractRootDomain(b.property)))
          .map((item) => {
          return (
            <MenuItem key={item.id} value={item.property}>
              <img
                src={`https://www.google.com/s2/favicons?sz=32&domain=${extractRootDomain(item.property) || 'example.com'}`}
                loading="lazy"
                alt="favicon"
                width={20}
                height={20}
                style={{ borderRadius: '50%', marginRight: '10px', verticalAlign: 'bottom' }}
              />
              <span style={{ filter: blur ? 'blur(4px)' : 'none' }}>{extractRootDomain(item.property)}</span>
            </MenuItem>
          );
          })

      ) : (
        <Button
          sx={{ p: 0 }}
          variant="text"
          className="capitalize w-full">
        </Button>
      )}

    </Select>
  );
};

export default Selector;
