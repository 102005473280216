export interface CountryType {
  country: string;
}

export const countries: ReadonlyArray<CountryType> = [
  { country: 'AR' },
  { country: 'CL' },
  { country: 'CO' },
  { country: 'DK' },
  { country: 'EC' },
  { country: 'ES' },
  { country: 'EE' },
  { country: 'FI' },
  { country: 'MX' },
  { country: 'SI' },
  { country: 'US' },
  { country: 'VE' }
];

export default countries;
