import './DomainCompetitor.scss';
import { Typography } from '@mui/material';
import { extractRootDomain } from '../../../utils/Utils';

interface Props {
  data: any;
  options: any;
  domain: string;
}

const DomainCompetitor = ({ domain, options, data }: Props) => {

  if (!domain) {
    return;
  }

  let className = '';
  const serp = data.serp_info.serp;
  const current = data.serp_info.serp.find(d => d.main_domain == extractRootDomain(domain));

  const maxRankAbsolute = serp.reduce((max, item) => {
    return item.rank_absolute < max ? item.rank_absolute : max;
  }, Infinity);

  if (maxRankAbsolute && maxRankAbsolute === current?.rank_absolute) {
    className = 'greater';
  }

  return (
    <Typography className={className}>{current?.rank_absolute || '—'}</Typography>
  );

};

export default DomainCompetitor;