import { useState } from 'react';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { KeywordResponse } from '../../../types';

export const DomainGeneralModel = () => {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, domain: false,
    domainOverview: false, ranges: false, searchIntent: false, bestPages: false, competitors: false, topCountriesByKeyword: false, topCountriesByTraffic: false });

  const[country, setCountry] = useState<string>(COUNTRY_CODE_ES)
  const[domainData, setDomainData] = useState<KeywordResponse>()
  const[keywordsOverviewData, setKeywordsOverviewData] = useState<KeywordResponse>()
  const[rankRangesData, setRankRangesData] = useState()
  const[searchIntentData, setSearchIntentData] = useState()
  const[bestPages, setBestPages] = useState()
  const[competitorsData, setCompetitorsData] = useState()
  const[topCountriesByKeyword, setTopCountriesByKeyword] = useState<number[]>([])
  const[topCountriesByTraffic, setTopCountriesByTraffic] = useState<number[]>([])

  return {
    isLoading, setIsLoading,
    country, setCountry,
    domainData, setDomainData,
    keywordsOverviewData, setKeywordsOverviewData,
    rankRangesData, setRankRangesData,
    searchIntentData, setSearchIntentData,
    bestPages, setBestPages,
    competitorsData, setCompetitorsData,
    topCountriesByKeyword, setTopCountriesByKeyword,
    topCountriesByTraffic, setTopCountriesByTraffic
  }

}