import './OverviewRedirectLink.scss'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from '../../utils/Constants'
import { classNames } from '../../utils/Utils'
import { useColorModeContext } from '../../context/ColorModeContext'

import { Typography } from '@mui/material'
import KeywordLinkBlank from '../KeywordLinkBlank'
import { FC } from 'react'
import { useSearchContext } from '../../context/SearchCacheContext'

interface Props {
  url: string;
  domain: string;
  location?: string | number;
}

const OverviewRedirectLink: FC<Props> = ({ url, domain, location }) => {

  // hooks
  const { inputValue, setInputValue, country } = useSearchContext();
  const { mode } = useColorModeContext();
  const navigate = useNavigate();


  const handleOnClick = () => {
    if(!inputValue){
      return
    }
    setInputValue(domain)
    navigate(`${ROUTES.DOMAIN_VIEW}?q=${domain}&country=${country}`);
  };

  return (
    <div className="flex flex-col py-2 overviewRedirectLink">
      <Typography
        component="span"
        className={classNames('btn-link link-domain', mode)}
        onClick={() => handleOnClick()}
        style={{ cursor: 'pointer', }}
      >{domain}
      </Typography>
      <KeywordLinkBlank href={url} />
    </div>
  );
};

export default OverviewRedirectLink;
