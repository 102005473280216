import './Backlink.scss'
import { useTranslation } from 'react-i18next'
import { Grid2, Typography, Tooltip, LinearProgress, Box } from '@mui/material'
import { BarChart, ChartsReferenceLine } from '@mui/x-charts'
import { formatNumber } from '../../../utils/Utils'

const BackLinkWinAndLost = ({ data, loading }) => {
  const { t } = useTranslation()

  let formattedData = data?.historyList.map((item) => ({
    date: item.date.split('T')[0].slice(0, 7).replace('-', '/'),
    new_backlinks: item.new_backlinks,
    lost_backlinks: item.lost_backlinks * -1
  })).slice(-12)

  if (!formattedData || formattedData.length === 0) {
    formattedData = [
      { date: '', new_backlinks: 0, lost_backlinks: 0 }
    ]
  }

  return (
    <div className='backLinksWinAndLost'>
      <Grid2 container spacing={2} className='container-analysis' justifyContent='space-between'>
        {/* left */}
        <Grid2>
          <Tooltip title={t('tooltip.newAndLostLinks')} followCursor arrow>
            <Typography>{t('newAndLostLinks')}</Typography>
          </Tooltip>
        </Grid2>
        {/* right */}
        <Grid2>
          <Tooltip title={t('newLinks')} followCursor arrow>
            <span className='green'>⬆{formatNumber(data?.newBacklinks)}</span>
          </Tooltip>
          <Tooltip title={t('lostLinks')} followCursor arrow>
            <span className='red'>⬇{formatNumber(data?.lostBacklinks)}</span>
          </Tooltip>
        </Grid2>
      </Grid2>

      {loading && <LinearProgress />}

      <Box sx={{ width: '100%', height: 160 }}>
        <BarChart
          dataset={formattedData}
          series={[
            { dataKey: 'new_backlinks', label: t('newLinks'), color: 'green', valueFormatter: (value) => formatNumber(value) },
            { dataKey: 'lost_backlinks', label: t('lostLinks'), color: 'red', valueFormatter: (value) => formatNumber(value * -1) },
          ]}
          xAxis={[{ scaleType: 'band', dataKey: 'date', disableLine: true }]}
          yAxis={[{ id: 'yaxis', disableLine: true }]}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          slotProps={{ legend: { hidden: true } }}
          barCategoryGap={0}
          barGap={0}
          borderRadius={5}
        >
          <ChartsReferenceLine y={0} />
        </BarChart>
      </Box>


    </div>
  )
}

export default BackLinkWinAndLost
