import { useAccessToken } from './useAccessToken';
import { crmService } from '../services/CrmService'

const RestCrmController = () => {

  const token = useAccessToken();

  interface restProps {
    params: any,
    endpoint: string
  }

  const rest = async ({ params, endpoint }:restProps) => {
    return await crmService[endpoint]({ ...params, accessToken: token });
  }

  return {
    rest
  }

}

export default RestCrmController
