import './CarList.scss'
import { Grid2, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatNumber, urlCountryFlag } from '../../utils/Utils'

interface Props {
  items: Object
  isFlag?: boolean,
  linkEnabled?: boolean,
  onClick?: (e) => Object,
  defaultValues?: Object
  widthKey?: number
  widthValue?: number
}

const CarList = ({items, isFlag, linkEnabled, onClick, defaultValues, widthKey, widthValue }:Props) => {
  const { t } = useTranslation()

  let isDataAvailable = false
  if(!items || Object.entries(items).length===0){
    items = defaultValues
  } else {
    isDataAvailable = true
  }

  const total = Object.values(items).reduce((acc, num) => acc + num, 0)

  return (
    <Grid2 className='cardList'>
      {
        Object.entries(items)
          .sort(([, a], [, b]) => b - a) // Sort by value (descending order)
          .slice(0, 20) // Take the top 5 items
          .map(([key, value]: [string, number]) => {
          const progressValue:number = (value * 100) / total || 0;
          const progressColor = progressValue === 0 ? "inherit" : "primary";

          if(key==='' || key==='unknown') {
            key= t('other')
          }

          return (
            <div className='items' onClick={linkEnabled && isDataAvailable ? (e) => onClick(e) : undefined}  key={key} style={{ cursor: linkEnabled && isDataAvailable ? 'pointer' : 'default' }} >
              <div style={{ width: widthKey || 40 }} className={`flag ${isDataAvailable?'':'disabled'}`}>{isFlag?(
                <img width="22" loading="lazy" alt={key} src={urlCountryFlag(key)} />
                ) : (
                  <Typography>{t(key)}</Typography>
                )}</div>
              <div className='progress'><LinearProgress color={progressColor} variant={'determinate'} value={progressValue} /></div>
              <div style={{ width: widthValue || 40 }} className='value'>{formatNumber(value)}</div>
            </div>
          )
        })
      }
    </Grid2>
  )

}

export default CarList
