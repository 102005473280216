import './KeywordMetrics.scss';
import { useTranslation } from 'react-i18next';

import { Keyword } from '../../types';
import { useColorModeContext } from '../../context/ColorModeContext';
import { formatNumber, generateUuid, keywordDifficultyValuesProgress } from '../../utils/Utils';
import { Divider, Grid2, Skeleton, Typography } from '@mui/material';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import DifficultyProgress from '../DifficultyProgress/DifficultyProgress';

const KeywordMetrics = ({ data }: { data?: Keyword }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  const { htmlClass } = keywordDifficultyValuesProgress(data?.extra?.keyword_difficulty ?? 0, mode);

  return (
    <Grid2 container spacing={1} className="KeywordMetrics" >
      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <CustomTooltip content={t('tooltip.keyword_info.search_volume')}>
          <Typography className="text-gray-500">{t('headerName.keyword_info.search_volume')}</Typography>
        </CustomTooltip>
          <Typography className="text-2xl font-bold search-volume" sx={{ margin: '0 0 50px' }}>
            {formatNumber(data?.keyword_info?.search_volume as number) || 0}
          </Typography>
        <Divider  />
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
          <CustomTooltip content={t('tooltips.keywords.explore_keywords_difficult')}>
            <Typography className="text-gray-500">{t('difficulty')}</Typography>
          </CustomTooltip>
          <DifficultyProgress
            htmlClass={htmlClass}
            value={data?.extra?.keyword_difficulty || 0}
          />
      </Grid2>
    </Grid2>
  )

};

export default KeywordMetrics;