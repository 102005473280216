import './KeywordExplorer.scss'
import { useTranslation } from 'react-i18next'
import { Grid2, Typography, Tooltip } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'

import KeywordExplorerModel from './KeywordExplorerModel'
import KeywordExplorerController from './KeywordExplorerController'
import Search from '../../../components/Search'
import KeywordMetrics from '../../../components/KeywordMetrics/KeywordMetrics'
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'
import TendencyChart from '../../../components/TendencyChart'
import LevelCompetition from '../../../components/LevelCompetition'
import TableComponent from '../../../components/TableComponent'
import KeywordMetadata from '../../../components/KeywordMetadata'
import ViewMoreButton from '../../../components/ViewMoreButton'
import { formatNumber } from '../../../utils/Utils'
import * as CONSTANTS from '../../../utils/TableColumnsConstants'
import CarList from '../../../components/CardList/CarList'
import { DEFAULT_CARD_LIST_COUNTRY } from '../../../utils/Constants'

export const KeywordExplorerView = () => {
  const { t } = useTranslation()

  const model = KeywordExplorerModel()
  const controller = KeywordExplorerController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 2 }}>
          <Search
            placeholder={t('search_placeholder_keyword')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
        </PaperItem>
      </Grid2>

      <Grid2 className='container-cart-date' size={{ xs: 12, sm: 4, md: 3 }}>
        <PaperItem className='boxShadow' sx={{ p: 2 }}>
          <KeywordMetrics data={model.keywordsOverviewData?.items} />
        </PaperItem>
      </Grid2>

      <Grid2 className='container-cart-date' size={{ xs: 12, sm: 4, md: 3 }}>
        <PaperItem className='boxShadow' sx={{ p: 2, height: '100%' }}>
          <Tooltip title={t('tooltip.globalVolume')} followCursor arrow>
            <Typography className='title'>{t('globalVolume')}</Typography>
          </Tooltip>
          <Typography className='enfatized'>{formatNumber(Object.values(model.topCountries.items || {}).reduce((acc, num) => acc + num, 0))}</Typography>
          <CarList items={model.topCountries.items} defaultValues={DEFAULT_CARD_LIST_COUNTRY} onClick={controller.handleOnClickCardLitItem} linkEnabled isFlag />
        </PaperItem>
      </Grid2>

      <Grid2 className='container-cart-date' size={{ xs: 12, sm: 8, md: 3 }}>
        <PaperItem className='boxShadow' sx={{ p: 2 }}>
          <CustomTooltip content={t('tooltips.keywords.explore_tendencies')}>
            <Typography>{t('tendencies')}</Typography>
          </CustomTooltip>
          <TendencyChart data={model.keywordsOverviewData?.items?.keyword_info?.history} />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 3 }}>
        <PaperItem className='boxShadow' sx={{ px: 2, height: '100%' }}>
          <LevelCompetition data={model.dataLevelCompetition} results={model.resultsLevelCompetition} />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 2 }}>
          <div className='flex flex-col gap-2'>
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('keyword_ideas')}</Typography>
            </CustomTooltip>
            <KeywordMetadata metadata={model.keywordsData} />
          </div>
          <TableComponent
            rowBuffer={0}
            columnBuffer={0}
            rows={model.keywordsData?.items}
            loading={model.isLoading.keywords}
            columns={CONSTANTS.COLUMNS_KEYWORD_ANALYSIS(t, {
              country: model.country
            })}
            paginationMode='client'
            sortingMode='client'
            hideFooter
            className='fullHeight'
          />
          <ViewMoreButton onClick={controller.redirectToIdeas} disabled={!model.keywordsData}>
            {`${t('view')} ${formatNumber(model.keywordsData?.totalKeywords)} ${t('more_keywords').toLocaleLowerCase()}`}
          </ViewMoreButton>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 2 }}>
          <CustomTooltip content={t('tooltips.keywords.explore_serp_overview')}>
            <Typography className='mb-5'>{t('serp_overview')}</Typography>
          </CustomTooltip>
          <TableComponent
            className='fullHeight'
            rowBuffer={0}
            columnBuffer={0}
            getRowHeight={() => 'auto'}
            rows={model.keywordsOverviewData?.items.serp_info?.serp}
            loading={model.isLoading.keywordOverview}
            columns={CONSTANTS.COLUMNS_KEYWORD_SERP_OVERVIEW(t)}
            paginationMode='client'
            sortingMode='client'
          />
        </PaperItem>
      </Grid2>
    </Grid2>
  )
}
