interface Props {
  value: {
    lines: {
      data: Array<{description: string}>
    }
  }
}

const InvoiceDescription = (value: Props) => {

  if(!value) {
    return
  }

  return value.value.lines.data[0].description;

};

export default InvoiceDescription;