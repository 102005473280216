import './BubbleChart.scss';
import { useTranslation } from 'react-i18next';

import { Key } from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import { formatCurrencyByLocation, formatNumber } from '../../utils/Utils';

const BubbleChart = ({ data, columns, loading }) => {
  const { t } = useTranslation();

  if(!data){
    return
  }

  const groupedData = data.reduce((acc, curr) => {
    if (!acc[curr.main_domain]) {
      acc[curr.main_domain] = [];
    }
    acc[curr.main_domain].push(curr);
    return acc;
  }, {});

  const scatterData = Object.keys(groupedData).map((main_domain, index) => ({
    name: main_domain,
    data: groupedData[main_domain],
    fill: columns.colors[index % columns.colors.length]
  }));

  const getItemValue = (data, dataKey) => {
    if(typeof(data[dataKey])==='number'){
      return formatNumber(data[dataKey]);
    } else {
      return <span className={'domain'}>{data[dataKey]}</span>;
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="tooltip container">
          {columns.tooltip.map((item: { title: Key | null | undefined; dataKey: number }) => (
            <div key={item.dataKey} className={'inner'}>
              <div className={'left'}>{t(item.dataKey.toString())}:</div>
              <div className={'right'}>{getItemValue(data, item.dataKey)}</div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={'93%'} className={'bubbleChart'}>
      <ScatterChart>
        <CartesianGrid />

        <XAxis type="number" dataKey={columns.columns.find((x) => (x.axe = 'x')).name} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />

        <YAxis type="number" dataKey={columns.columns.find((y) => (y.axe = 'y')).name} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />

        <ZAxis
          type="number"
          range={[1000, 10000]}
          dataKey={columns.columns.find((x) => (x.axe = 'x')).name}
        />

        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip active={undefined} payload={undefined} />}
        />
        <Legend />
        {scatterData.map((group) => (
          <Scatter key={group.name} name={group.name} data={group.data} fill={group.fill} />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default BubbleChart;
