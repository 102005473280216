import { createContext, useContext, useState, ReactNode } from 'react';
import { COUNTRY_CODE_ES } from '../utils/Constants';

interface SearchContextType {
  inputValue: string;
  setInputValue: (value: string) => void;
  country: string;
  setCountry: (country: string) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [country, setCountry] = useState<string>(COUNTRY_CODE_ES);

  return (
    <SearchContext.Provider value={{ inputValue, setInputValue, country, setCountry }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};