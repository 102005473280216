import './KeywordLinkBlank.scss';

import { classNames, isURL } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

interface Props {
  href: string;
  title?: string;
}

const KeywordLinkBlank: React.FC<Props> = ({ href, title }) => {
  const { mode } = useColorModeContext();
  const validateUrl = isURL(href);

  return (
    <a
      target="_blank"
      href={validateUrl}
      rel="noopener noreferrer"
      className={classNames('btn-link keywordLinkBlank', mode)}>
      <div>
        <span>{title ?? validateUrl}</span>
        <LaunchRoundedIcon className="noColor" fontSize="small" />
      </div>
    </a>
  );
};

export default KeywordLinkBlank;
