import { useState } from 'react'

const InvoicesModel = () => {
  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ invoices: false });
  const[invoicesData, setInvoicesData] = useState<any>()

  return {
    isLoading, setIsLoading,
    invoicesData, setInvoicesData
  }
}

export default InvoicesModel;