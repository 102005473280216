import './Breadcrumb.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { COLOR_MODE_DARK } from '../../utils/Constants';
import { BreadcrumbsTypes } from '../../types/common.type';
import { DRAWER_RIGHT_TUTORIAL } from '../../utils/tutorials';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useColorModeContext } from '../../context/ColorModeContext';
import { useUserContext } from '../../context/UserContext';
import { classNames, generateIcon, generateUuid, getBreadcrumbs } from '../../utils/Utils';
import { Box, Breadcrumbs, Button, ButtonGroup, CircularProgress, Drawer, Link, Typography } from '@mui/material';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const cleanUrl = (url: string) =>
  decodeURIComponent(url)
    .replace(/^(?:https?:\/\/)?(?:www\.)?(?:sc-domain:)?/, '')
    .replace(/\/$/, '');

const Breadcrumb = ({ links = [] }: { links?: BreadcrumbsTypes }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { blur } = useUserContext();

  const toggleDrawer = (value: boolean) => setOpen(value);

  const breadcrumbLinks = links.length > 0 ? links : getBreadcrumbs();
  const { t } = useTranslation();
  const location = useLocation();
  const { mode } = useColorModeContext();

  const documentTitle = t(
    `routes.${breadcrumbLinks[breadcrumbLinks.length - 1].title.toLowerCase()}`
  );

  useDocumentTitle(documentTitle !== 'routes.' ? documentTitle : t('home.home'));

  const getTutorialInfo = (pathname: string) => {
    if (pathname.startsWith('/projects/performance/detail/')) {
      return DRAWER_RIGHT_TUTORIAL['PROJECTS_DETAIL_DYNAMIC'];
    }
    return DRAWER_RIGHT_TUTORIAL[pathname] || { url: '#', description: '' };
  };

  const drawerRightMemoize = React.useMemo(() => getTutorialInfo(location.pathname), [location.pathname]);

  const breadCrumbLinks = React.useMemo(() => {
    const links: Array<any> = [];
    breadcrumbLinks.forEach((link, index: number) => {
      let element: any = undefined;
      const title = link.title.toLowerCase();
      const match = location.pathname.match(/\/projects\/performance\/detail\/(.+)/);
      const isDynamicRoute = !!match;

      let displayTitle = t(`routes.${title}`);
      const shouldBlur = blur && isDynamicRoute && link.href === '/projects/performance/detail';

      if (isDynamicRoute && link.href === '/projects/performance/detail') {
        displayTitle = cleanUrl(match[1]);
      }

      let tooltipContent = '';
      if (!(isDynamicRoute && link.href === '/projects/performance/detail')) {
        tooltipContent = t(`tooltips.breadcrumbs.${link.path ? `${link.path}_` : ''}${title}`);
      }

      const hrefWithSlash = link.href.endsWith('/') ? link.href : `${link.href}/`;

      if (breadcrumbLinks.length === 1 || index + 1 < breadcrumbLinks.length) {
        element = (
          <Link
            key={generateUuid()}
            underline="hover"
            href={hrefWithSlash}
            className="link"
            component={Link}
          >
            {link.icon && generateIcon(link.icon)}
            <span style={{ filter: shouldBlur ? 'blur(4px)' : 'none' }}>{displayTitle}</span>
          </Link>
        );
      } else if (title) {
        element = (
          <Typography key={generateUuid()}>
            <span style={{ filter: shouldBlur ? 'blur(4px)' : 'none' }}>{displayTitle}</span>
          </Typography>
        );
      }

      if (element !== undefined) {
        if (tooltipContent !== '') {
          links.push(
            <CustomTooltip key={generateUuid()} content={tooltipContent}>
              {element}
            </CustomTooltip>
          );
        } else {
          links.push(element);
        }
      }
    });

    return links;
  }, [location.pathname, breadcrumbLinks, blur, t]);

  return (
    <div className={classNames('breadcrumb', mode)}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <svg
            fill="none"
            aria-hidden="true"
            viewBox="0 0 6 10"
            style={{ width: 10, height: 10 }}
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeWidth="2"
              d="m1 9 4-4-4-4"
              data-color={mode}
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke={mode === COLOR_MODE_DARK ? '#FFF' : 'black'}
            />
          </svg>
        }>
        {breadCrumbLinks}
      </Breadcrumbs>
      {drawerRightMemoize && (drawerRightMemoize.url.length > 1) && (
        <React.Fragment>
          <ButtonGroup>
            <Button className="capitalize btn-more-competitors" onClick={() => toggleDrawer(true)}>
              <OndemandVideoIcon sx={{ marginRight: "10px" }} />
              {t('tutorial')}
            </Button>
          </ButtonGroup>
          <Drawer
            open={open}
            anchor="right"
            variant="temporary"
            onClose={() => toggleDrawer(false)}>
            <div className="flex justify-end my-3">
              <Button onClick={() => toggleDrawer(false)}>
                {generateIcon('Close', { fontSize: 'small' })}
              </Button>
            </div>
            <Box className="p-4" sx={{ maxWidth: 400, width: '100%' }} role="presentation">
              <Box className="w-full mb-5 video-bg">
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen={true}
                  title={generateUuid()}
                  src={drawerRightMemoize.url}
                />
              </Box>
              <p
                className={classNames(mode === 'dark' ? 'text-light' : 'text-dark')}
                dangerouslySetInnerHTML={{ __html: drawerRightMemoize.description }}
              />
            </Box>
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
};

export default Breadcrumb;