import { Box, Grid2, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'
import PositionsModel from './PositionsModel'
import PositionsController from './PositionsController'
import TableComponent from '../../../components/TableComponent'
import ButtonExportExcel from '../../../components/Buttons/ExportExcel'
import { COLUMNS_DOMAIN_POSITIONS } from '../../../utils/TableColumnsConstants'
import Filter from '../../../components/Filter/Filter'
import { FILTER_INTENTION, FILTER_KEYWORD_DIFFICULTY, FILTER_VOLUME } from '../../../utils/Constants'

export const PositionsView = () => {
  const { t } = useTranslation()

  const model = PositionsModel()
  const controller = PositionsController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='flex-col gap-2 boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
          <div className='flex gap-2 w-full pt-3'>
            <Filter name='volume' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='cpc' items={[]} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='kd' items={FILTER_KEYWORD_DIFFICULTY} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='intention' items={FILTER_INTENTION} custom={false} onChange={controller.handleFilterUpdate} />
          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className='mb-5'>{t('organic_keywords')}</Typography>
            <Box sx={{
              width: 120,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              <ButtonExportExcel
                isLoading={model.isLoading.export}
                onClick={controller.handleExportBtn}
                totalKeywords={model.keywordsData?.totalKeywords || 0}
              />
            </Box>
          </Box>
          <TableComponent
            className='fullHeight'
            rows={model.keywordsData?.items}
            rowCount={model.keywordsData?.totalKeywords || 0}
            columns={COLUMNS_DOMAIN_POSITIONS(t)}
            loading={model.isLoading.keywords}
            paginationModel={model.paginationModel}
            onPaginationModelChange={model.setPaginationModel}
            sortModel={model.sortModel}
            onSortModelChange={model.setSortModel}
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            sortingMode='server'
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
