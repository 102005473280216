import RestKeywordController from '../../../hooks/RestKeywordController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import useExportData from '../../../hooks/useExportData';
import RestDomainController from '../../../hooks/RestDomainController';
import { parseStringToArray } from '../../../utils/Utils';

export const NicheDomainsController = (model)=> {

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country } = useSearchContext();

  // functions

  // Applu params when any of the filter changes
  const onFilter = useCallback((name, from, to, action) => {
    model.setFilter((prevItems) => {
      const newItems = new Map([...prevItems]);

      // Initialize an object to store filter values directly
      const payload = {};

      if (action === "delete") {
        // Remove the filter keys from the Map
        newItems.delete(`${name}From`);
        newItems.delete(`${name}To`);
        newItems.delete(`${name}`);
      } else if (name === 'intention'|| name === 'tld') {
        newItems.set(`${name}`, action);
      } else {
        if (from) newItems.set(`${name}From`, from);
        if (to) newItems.set(`${name}To`, to);
      }

      // Convert the Map into an object with only raw values
      newItems.forEach((value, key:any) => {
        payload[key] = value; // Store only the value, no extra object
      });

      // Update model state (if needed)
      model.setFilter(newItems);

      return new Map(newItems); // Ensure React detects changes
    });
  },[model.filter])

  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { keywords: parseStringToArray(inputValue), country: country };

    model.filter.forEach((value, key) => {
      params[key] = value;
    });

    model.setIsLoading((prev) => ({ ...prev, search: true }))
    model.setIsLoading((prev) => ({ ...prev, keywords: true }))
    rest({ params, endpoint: 'getIdeaOverview' })
      .then((data) => {
        model.setKeywordsData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, keywords: false }))
        model.setIsLoading((prev) => ({ ...prev, search: false }))
      })
  }, [inputValue, country, model.keywordsData, model.setKeywordsOverviewData, model.filter]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }


  // Export to excel
  const { isLoading, fetchExportKeywordsNicheIdeas } = useExportData();
  const handleExportBtn = async () => {
    model.setIsLoading({ export: true })
    const keywords = model.keywordsData.items.map(item => item.keyword).filter(kw => typeof kw === 'string')
    const params = { keywords: keywords, country: country};
    await fetchExportKeywordsNicheIdeas({ ...params, title: 'Ideas' });
    model.setIsLoading({ export: false })
  }

  return {
    onFilter,
    handleSearchBtn,
    handleCountryUpdate,
    handleExportBtn
  }
}