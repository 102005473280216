import './BackLinks.scss'
import { Divider, Grid2, Tooltip, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'

import TableComponent from '../../../components/TableComponent'
import { COLUMNS_DOMAIN_BACKLINKS } from '../../../utils/TableColumnsConstants'
import { BacklinksModel } from './BacklinksModel'
import { BacklinksController } from './BacklinksController'
import BackLinkType from '../../../components/Charts/BacklinkType/BackLinkType'
import BackLinkWinAndLost from '../../../components/Charts/BacklinkType/BackLinkWinAndLost'
import { formatNumber } from '../../../utils/Utils'
import {
  DEFAULT_CARD_LINK_ATTRIBUTES,
  DEFAULT_CARD_LINK_LOCATIONS,
  DEFAULT_CARD_LINK_LTD,
  DEFAULT_CARD_LINK_PLATFORM,
  DEFAULT_CARD_LINK_TYPES,
  DEFAULT_CARD_LIST_COUNTRY
} from '../../../utils/Constants'
import CarList from '../../../components/CardList/CarList'

export const BacklinksView = () => {
  const { t } = useTranslation()

  const model = BacklinksModel()
  const controller = BacklinksController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
        </PaperItem>
      </Grid2>

      {/* left */}
      <Grid2 container size={{ xs: 9 }}>
        {/* first row */}
        <Grid2 size={{ xs: 4 }}>
          {/* total and broken */}
          <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
            <Grid2 container justifyContent='space-between'>
              <Grid2>
                <Tooltip title={t('tooltip.totalBacklinks')} followCursor arrow>
                  <Typography className='titleName'>{t('totalBacklinks')}</Typography>
                </Tooltip>
                <Typography className='value'>{formatNumber(model.backLinksData?.total) || 0}</Typography>
                <Divider className='divider' />
                <Tooltip title={t('tooltip.totalBacklinksBroken')} followCursor arrow>
                  <Typography className='titleName'>{t('totalBacklinksBroken')}</Typography>
                </Tooltip>
                <Typography className='value red'>{formatNumber(model.backLinksData?.broken) || 0}</Typography>
              </Grid2>
              <Grid2 justifyItems='end'>
                <Tooltip title={t('tooltip.backlinkDomains')} followCursor arrow>
                  <Typography className='titleName'>{t('domain')}s</Typography>
                </Tooltip>
                <Typography className='value'>{formatNumber(model.backLinksData?.domains) || 0}</Typography>
              </Grid2>
            </Grid2>

          </PaperItem>
        </Grid2>
        <Grid2 size={{ xs: 8 }}>
          {/* historic */}
          <PaperItem className='boxShadow' sx={{ p: 2 }}>
            <BackLinkWinAndLost data={model.backLinksData} loading={model.isLoading.backlinks} />
          </PaperItem>
        </Grid2>
        {/* second row */}
        <Grid2 size={{ xs: 4 }}>
          {/* attributes table */}
          <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
            <Tooltip title={t('tooltip.linksAttributes')} followCursor arrow>
              <Typography>{t('linkTypes')}</Typography>
            </Tooltip>
            <CarList items={model.backLinksData?.linksAttributes} defaultValues={DEFAULT_CARD_LINK_ATTRIBUTES} widthKey={160} widthValue={90} />
          </PaperItem>
        </Grid2>
        <Grid2 size={{ xs: 8 }}>
          {/* attributes chart */}
          <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
            <BackLinkType data={model.backLinksData?.linksAttributes} loading={model.isLoading.backlinks} />
          </PaperItem>
        </Grid2>
      </Grid2>

      {/* right */}
      <Grid2 size={{ xs: 3 }}>
        <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
          {/* Countries */}
          <Tooltip title={t('tooltip.countries')} followCursor arrow>
            <Typography>{t('countries')}</Typography>
          </Tooltip>
          <CarList items={model.backLinksData?.linksCountries} defaultValues={DEFAULT_CARD_LIST_COUNTRY} isFlag widthValue={90} />
        </PaperItem>
      </Grid2>
      {/* third row */}
      <Grid2 size={{ xs: 3 }}>
        <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
          {/* locations */}
          <Tooltip title={t('tooltip.linksLocations')} followCursor arrow>
            <Typography>{t('linksLocations')}</Typography>
          </Tooltip>
          <CarList items={model.backLinksData?.linksLocations} defaultValues={DEFAULT_CARD_LINK_LOCATIONS} widthKey={100} widthValue={90} />
        </PaperItem>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
          {/* tld */}
          <Tooltip title={t('tooltip.linksLtd')} followCursor arrow>
            <Typography>{t('linksLtd')}</Typography>
          </Tooltip>
          <CarList items={controller.getCleanLtd()} defaultValues={DEFAULT_CARD_LINK_LTD} widthKey={80} widthValue={90} />
        </PaperItem>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
          {/* platform */}
          <Tooltip title={t('tooltip.linksPlatform')} followCursor arrow>
            <Typography>{t('linksPlatform')}</Typography>
          </Tooltip>
          <CarList items={model.backLinksData?.linksPlatform} defaultValues={DEFAULT_CARD_LINK_PLATFORM} widthKey={180} widthValue={90} />
        </PaperItem>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <PaperItem className='boxShadow customCard' sx={{ p: 2 }}>
          {/* types */}
          <Tooltip title={t('tooltip.linksTypes')} followCursor arrow>
            <Typography>{t('linksTypes')}</Typography>
          </Tooltip>
          <CarList items={model.backLinksData?.linksTypes} defaultValues={DEFAULT_CARD_LINK_TYPES} widthKey={160} widthValue={90} />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <TableComponent
            getRowHeight={() => 'auto'}
            rows={model.backLinksData?.backLinks}
            loading={model.isLoading.backlinks}
            columns={COLUMNS_DOMAIN_BACKLINKS(t)}
            paginationMode='client'
            sortingMode='client'
            className='fullHeight'
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
