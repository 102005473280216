import { useState } from 'react';
import * as CONSTANTS from '../utils/Constants';
import { GridPaginationModel } from '@mui/x-data-grid-premium';

const usePaginationModel = () => {

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: CONSTANTS.PAGE_SIZE
  });

  return {
    paginationModel, setPaginationModel
  }
}

export default usePaginationModel