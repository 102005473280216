export const googleUpdatesJsonData = [
  { created: 1733698800000, duration: 2, link: 'https://status.search.google.com/incidents/V9nDKuo6nWKh2ThBALgA', text: '20241209Text', description: '20241209Description', htmlKey: '20241209Html' },
  { created: 1731279600000, duration: 2, link: 'https://status.search.google.com/incidents/G7rp11wqTaTGn6JjiPF3', text: '20241111Text', description: '20241111Description', htmlKey: '20241111Html' },
  { created: 1723680000000, duration: 16, link: 'https://status.search.google.com/incidents/gVx6b2o78zke7GrMidGy', text: '20240815Text', description: '20240815Description', htmlKey: '20240815Html' },
  { created: 1718834400000, duration: 10, link: 'https://status.search.google.com/incidents/QdUeCQx3LRVbzQ3E7FiD', text: '20240620Text', description: '20240620Description', htmlKey: '20240620Html' },
  { created: 1714860000000, duration: 7, link: 'https://status.search.google.com/incidents/iXz2PJfodvyjaVUeqxZE', text: '20240505Text', description: '20240505Description', htmlKey: '20240505Html' },
  { created: 1709593200000, duration: 15, link: 'https://status.search.google.com/incidents/iXz2PJfodvyjaVUeqxZE', text: '20240305Text', description: '20240305Description', htmlKey: '20240305Html' },
  { created: 1699398000000, duration: 5, link: 'https://status.search.google.com/incidents/VqRTcmyQCwfpuYeaVNfn', text: '20231108Text', description: '20231108Description', htmlKey: '20231108Html' },
  { created: 1698879600000, duration: 12, link: 'https://status.search.google.com/incidents/WtY1CQgfqrr2cjxKbyUk', text: '20231102Text', description: '20231102Description', htmlKey: '20231102Html' },
  { created: 1696456800000, duration: 10, link: 'https://status.search.google.com/incidents/VKyoS53ULWzSp8BoDxqk', text: '20231005Text', description: '20231005Description', htmlKey: '20231005Html' },
  { created: 1696370400000, duration: 6, link: 'https://status.search.google.com/incidents/NzcEhGMDhbQEdXCS35xL', text: '20231004Text', description: '20231004Description', htmlKey: '20231004Html' },
  { created: 1694642400000, duration: 14, link: 'https://status.search.google.com/incidents/53diuQvcEsgzqXTPBb8p', text: '20230914Text', description: '20230914Description', htmlKey: '20230914Html' },
  { created: 1692655200000, duration: 11, link: 'https://status.search.google.com/incidents/nBtYtBeex4GYBbdDS2LX', text: '20230822Text', description: '20230822Description', htmlKey: '20230822Html' },
  { created: 1681250400000, duration: 8, link: 'https://status.search.google.com/incidents/5XRfC46rorevFt8yN8iR', text: '20230412Text', description: '20230412Description', htmlKey: '20230412Html' },
  { created: 1678834800000, duration: 13, link: 'https://status.search.google.com/incidents/Cou8Tr74r7EXNthuEsaG', text: '20230315Text', description: '20230315Description', htmlKey: '20230315Html' },
  { created: 1676934000000, duration: 7, link: 'https://developers.google.com/search/updates/ranking?hl=es', text: '20230221Text', description: '20230221Description', htmlKey: '20230221Html' },
  { created: 1734562800000, duration: 2, link: 'https://status.search.google.com/incidents/UUq2WSouY7PhSm8zvtD1', text: '20241219Text', description: '20241219Description', htmlKey: '20241219Html' },
  { created: 1741820400000, duration: 14, link: 'https://status.search.google.com/incidents/zpmwuSwifjDjfrVdaZUx', text: '20250313Text', description: '20250313Description', htmlKey: '20250313Html' }
]
