import { format, subMonths } from 'date-fns';

export const ORDER_ASC = 'asc';
export const NUMBER_MAX = 'MAX';
export const NUMBER_MIN = 'MIN';
export const PAGE_SIZE = 10;

export const KEYWORD_DIFFICULTY_CLASS_VERY_EASY = 'very-easy';
export const KEYWORD_DIFFICULTY_CLASS_EASY = 'easy';
export const KEYWORD_DIFFICULTY_CLASS_POSSIBLE = 'possible';
export const KEYWORD_DIFFICULTY_CLASS_DIFFICULT = 'difficult';
export const KEYWORD_DIFFICULTY_CLASS_HARD = 'hard';
export const KEYWORD_DIFFICULTY_CLASS_VERY_HARD = 'very-hard';
export const KEYWORD_DIFFICULTY_MAX_VALUE_VERY_EASY = 14;
export const KEYWORD_DIFFICULTY_MAX_VALUE_EASY = 29;
export const KEYWORD_DIFFICULTY_MAX_VALUE_POSSIBLE = 49;
export const KEYWORD_DIFFICULTY_MAX_VALUE_DIFFICULT = 69;
export const KEYWORD_DIFFICULTY_MAX_VALUE_HARD = 79;
export const KEYWORD_DIFFICULTY_MAX_VALUE_VERY_HARD = 100;

export const ANCHOR = 'anchor'
export const ANSWER_TYPE_ANSWER = 'answer';
export const ANSWER_TYPE_TABLE = 'table';
export const ANSWER_TYPE_UNORDERED_LIST = 'unordered_list';
export const APP_NAME = 'Rankerfy';
export const BASE_URL = 'https://rankerfy.com';
export const CANNIBALIZATION_CLICKS = 'cannibalization_clicks'
export const CANNIBALIZATION_IMPRESSIONS = 'cannibalization_impressions'
export const CANNIBALIZATION_POSITIONS = 'cannibalization_positions'
export const CANNIBALIZATION_URLS = 'cannibalization_urls'
export const COLOR_MODE_DARK = 'dark';
export const COLOR_MODE_LIGHT = 'light';
export const COMPARE_URL = 'compare_url';
export const CONTAINS_KEYWORD = 'contains_keyword';
export const CREATED = 'created'
export const DISTRIBUTION = 'distribution'
export const DOMAIN_IDEAS = 'domain_ideas'
export const DOMAIN_IDEAS_URLS = 'domain_ideas_urls'
export const DOMAIN_POSITION_KEYWORD = 'domain_position.keyword'
export const INVOICE_AMOUNT = 'invoiceAmountDue'
export const INVOICE_CREATED = 'invoiceCreated'
export const INVOICE_CURRENCY = 'invoice_currency'
export const INVOICE_DESCRIPTION = 'invoiceDescription'
export const INVOICE_DOWNLOAD = 'invoiceDownload';
export const INVOICE_STATUS = 'invoiceStatus'
export const DOMAIN = 'domain';
export const DOMAIN_0 = 'domain_0';
export const DOMAIN_1 = 'domain_1';
export const DOMAIN_2 = 'domain_2';
export const MAIN_DOMAIN = 'main_domain';
export const EXTRA_KEYWORD_DIFFICULTY = 'extra.keyword_difficulty';
export const CREATED_AT = 'createdAt'
export const HISTORY_TREND = 'history_trend';
export const KEYWORD = 'keyword';
export const KEY_COMMAN_NAME = ',';
export const KEY_ENTER_NAME = 'Enter';
export const KEYWORD_INFO_CPC = 'keyword_info.cpc';
export const KEYWORD_INFO_HISTORY = 'keyword_info.history';
export const LAST_SEEN = 'updatedAt';
export const LIMIT_SCREEN_INNER_WIDTH = 1024;
export const COUNTRY_CODE_ES = 'ES';
export const NOREFERRER = 'noopener noreferrer';
export const PAGE_FROM_TITLE = 'page_from_title'
export const PLAN_BILLING_MONTHLY = 'monthly'
export const PLAN_BILLING_YEARLY = 'yearly'
export const PLAN_ADVANCED = 'ADVANCED'
export const PLAN_ADVANCED_PRICE = 38
export const PLAN_DISCOUNT_ANUAL = 0.1
export const PLAN_EXPERT = 'EXPERT'
export const PLAN_EXPERT_PRICE = 70
export const PLAN_ROOKIE = 'ROOKIE'
export const PLAN_ROOKIE_PRICE = 30
export const POSITION = 'position';
export const PROJECT_ACCOUNT = 'project_account'
export const PROJECT_ACCOUNT_INFO = 'project_accountInfo'
export const PROJECT_PROPERTY = 'project_property'
export const RANK_RANGES = "rank_ranges";
export const SEARCH_INTENT = "search_intent";
export const SEARCH_INTENT_INFO_INTENT = 'search_intent_info.intent';
export const SEARCH_INTENT_INFO_INTENT_WITH_DESCRIPTION = 'search_intent_info.intentWithDescription'
export const SERP_INFO_ITEM_TYPES = 'serp_info.item_types';
export const SERP_INFO_SERP_0_TRAFFIC_COST = 'serp_info.serp.0.traffic_cost'
export const SERP_INFO_SERP_POSITION = 'serp_info.serp.position'
export const SERP_INFO_SERP_RANK_ABSOLUTE = 'serp_info.serp.rank_absolute'
export const SERP_INFO_SERP_KEYWORD = 'serp_info.serp.keyword'
export const SERP_INFO_SERP_DOMAIN = 'serp_info.serp.domain'
export const THIN_CONTENT_PROBLEMS = 'thinContent_problems'
export const TOKEN_PROJECT = 'PROJECT_LISTS';
export const TOTAL_TRAFFIC_COST = "total_traffic_cost"
export const TRAFFICOST = "trafficCost"
export const TRAFFIC_COST = 'traffic_cost'
export const URL = 'url';

export const OPPORTUNITY_LINKS = new Map([
  ['duplicated-javascript', 'https://gtmetrix.com/remove-duplicate-modules-in-javascript-bundles.html'],
  ['legacy-javascript', 'https://gtmetrix.com/avoid-using-legacy-javascript.html'],
  ['uses-text-compression', 'https://gtmetrix.com/enable-text-compression.html'],
  ['uses-responsive-images', 'https://gtmetrix.com/serve-scaled-images.html'],
  ['unminified-css', 'https://gtmetrix.com/minify-css.html'],
  ['render-blocking-resources', 'https://gtmetrix.com/eliminate-render-blocking-resources.html'],
  ['unused-javascript', 'https://gtmetrix.com/remove-unused-javascript.html'],
  ['prioritize-lcp-image', 'https://gtmetrix.com/optimize-lcp.html'],
  ['unminified-javascript', 'https://gtmetrix.com/minify-javascript.html'],
  ['offscreen-images', 'https://gtmetrix.com/defer-offscreen-images.html'],
  ['efficient-animated-content', 'https://gtmetrix.com/avoid-large-animations.html'],
  ['uses-optimized-images', 'https://gtmetrix.com/optimize-images.html'],
  ['unused-css-rules', 'https://gtmetrix.com/remove-unused-css.html'],
  ['modern-image-formats', 'https://gtmetrix.com/serve-images-in-next-gen-formats.html'],
  ['server-response-time', 'https://gtmetrix.com/reduce-initial-server-response-time.html'],
  ['redirects', 'https://gtmetrix.com/avoid-multiple-page-redirects.html']
]);


export const FORMAT_DATE_DAYJS = 'YYYY-MM-DD';
export const MENU_ITEM_CUSTOM = 'custom'

export const FORMAT_DATE_RANGE = 'yyyy-MM-dd';

export enum ROUTES {
  HOME = '/',
  ACCOUNT = '/account/',
  ACCOUNT_BILLING = '/account/billings/',
  ACCOUNT_CONNECTIONS = '/account/connections/',
  BATCH_ANALYSIS = '/keywords/bulk-analysis/',
  BUY_PLAN_USER = '/buy-plan/',
  DOMAIN_BACKLINKS = '/domain/backlinks/',
  DOMAIN_BULK_ANALYSIS = '/domain/bulk-analysis/',
  DOMAIN_COMPARATOR = '/domain/content-gap/',
  DOMAIN_COMPETITORS = '/domain/competitors/',
  DOMAIN_KEYWORDS = '/domain/keywords/',
  DOMAIN_VIEW = '/domain/view/',
  DOMAINS = '/domain/',
  EXPLORE = '/keywords/explorer/',
  IDEAS = '/keywords/ideas/',
  KEYWORDS = '/keywords/',
  PROJECTS = '/projects/',
  PROJECTS_CANNIBALIZATION = '/projects/cannibalization/',
  PROJECTS_CONTENT = '/projects/thin-content/',
  PROJECTS_MANAGE = '/projects/manage/',
  PROJECTS_OPPORTUNITY = '/projects/opportunity-keywords/',
  PROJECTS_PERFORMANCE = '/projects/performance/',
  ACCOUNT_SUPPORT = '/account/support/',
  THANKS = '/thanks/',
  NICHE_KEYWORDS = '/niche/keywords/',
  NICHE_DOMAINS = '/niche/domains/',
  NICHE_IDEAS = '/niche/'
}

export enum SweetAlertIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export const DEFAULT_VALUES_FILTER = Object.freeze({
  from: 0,
  to: 0
});

export const MOCK_DATA_KEYWORDS_SERVICES = Object.freeze({
  items: [],
  avgCpc: undefined,
  totalKeywords: undefined,
  totalSearchVolume: undefined,
  avgKeywordDifficulty: undefined
});

export const DOMAIN_BUBBLE_CHART_COLUMNS = {
  title: 'domain',
  tooltip: [
    { dataKey: 'main_domain' },
    { title: 'traffic', dataKey: 'total_traffic' },
    { title: 'total_keywords', dataKey: 'keywords_count' },
    { title: 'traffic_cost', dataKey: 'total_traffic_cost' }
  ],
  columns: [
    { axe: 'y', name: 'total_traffic', group: 'main_domain' },
    { axe: 'x', name: 'keywords_count', group: 'main_domain' }
  ],
  colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink']
};

export const ALL_FILTER_MODE_IDEAS = ['related', 'questions', 'all'] as const;
export const FILTER_MODE_MAPPING = {
  'related': 'kws',
  'all': 'all',
  'questions': 'questions'
};

export const ALL_FILTER_MODE_CONTENT_GAP = ['common', 'missing', 'unique'] as const;
export const ALL_FILTER_MODE_OPPORTUNITY = ['all', 'included', 'not_included'] as const;


export const clicksOfSearchesOptions = [
  {
    value: 10,
    label: '0-10'
  },
  {
    value: 50,
    label: '11-50'
  },
  {
    value: 150,
    label: '51-150'
  },
  {
    value: 500,
    label: '151-500'
  },
  {
    value: 2500,
    label: 'más de 500'
  }
];

export const potitionOfSearchesOptions = [
  {
    value: 10,
    label: '0-10'
  },
  {
    value: 30,
    label: '11-30'
  },
  {
    value: 70,
    label: '31-70'
  },
  {
    value: 90,
    label: '71-90'
  },
  {
    value: 100,
    label: '91-100'
  }
];

export const CRTOfSearchesOptions = [
  {
    value: 1,
    label: '0-1'
  },
  {
    value: 5,
    label: '1-5'
  },
  {
    value: 10,
    label: '5-10'
  },
  {
    value: 15,
    label: '10-15'
  },
  {
    value: 25,
    label: 'más de 15'
  }
];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const DEFAULT_VALUE_RANGE = Object.freeze({
  key: 'selection',
  endDate: new Date(),
  startDate: subMonths(new Date(), 1)
});

export const DEFAULT_DATE_RANGE = Object.freeze({
  endDate: format(new Date(), FORMAT_DATE_RANGE),
  startDate: format(subMonths(new Date(), 1), FORMAT_DATE_RANGE)
});

export const FILTER_VOLUME = [
  { from: 0, to: 1 },
  { from: 11, to: 100 },
  { from: 101, to: 1000 },
  { from: 1001, to: 10000 },
  { from: 10001, to: 100000 },
  { from: 100001 }
]

export const FILTER_KEYWORD_DIFFICULTY = [
  { from: 0, to: 14, tooltip: 'difficultyLevel.veryEasy.description', append: '%', prepend: 'difficultyLevel.veryEasy.title' },
  { from: 15, to: 29, tooltip: 'difficultyLevel.easy.description', append: '%', prepend: 'difficultyLevel.easy.title' },
  { from: 30, to: 49, tooltip: 'difficultyLevel.possible.description', append: '%', prepend: 'difficultyLevel.possible.title' },
  { from: 50, to: 60, tooltip: 'difficultyLevel.difficult.description', append: '%', prepend:  'difficultyLevel.difficult.title' },
  { from: 70, to: 84, tooltip: 'difficultyLevel.hard.description', append: '%', prepend: 'difficultyLevel.hard.title' },
  { from: 85, to: 100, tooltip: 'difficultyLevel.veryHard.description', append: '%', prepend: 'difficultyLevel.veryHard.title' }
]

export const FILTER_INTENTION = [
  { type: 'check', key: 'c', label: 'Commercial', editable: false },
  { type: 'check', key: 'i', label: 'Informational', editable: false },
  { type: 'check', key: 'n', label: 'Navigational', editable: false },
  { type: 'check', key: 't', label: 'Transactional', editable: false }
]

export const FILTER_URLS = [
  { from: 0, to: 10, prepend: 'urlVeryLow' },
  { from: 11, to: 50, prepend: 'urlLow' },
  { from: 51, to: 100, prepend: 'urlEnough'},
  { from: 101, prepend: 'urlToMuch' }
]

export const FILTER_TLD = [
  { type: 'check', key: 'com', label: '.com', editable: false },
  { type: 'check', key: 'edu', label: '.edu', editable: false },
  { type: 'check', key: 'es', label: '.es', editable: false },
  { type: 'check', key: 'gov', label: '.gov', editable: false },
  { type: 'check', key: 'info', label: '.info', editable: false },
  { type: 'check', key: 'mil', label: '.mil', editable: false },
  { type: 'check', key: 'net', label: '.net', editable: false },
  { type: 'check', key: 'org', label: '.org', editable: false }
]

export const DEFAULT_CARD_LIST_COUNTRY = { ES: 0, US: 0, MX: 0, DK: 0, SI: 0 }
export const DEFAULT_CARD_LINK_ATTRIBUTES = { follow: 0, nofollow: 0, noopener: 0, noreferrer: 0, external: 0, sponsored: 0 }
export const DEFAULT_CARD_LINK_LOCATIONS = { article: 0, section: 0, figure: 0, main: 0, aside: 0, other: 0 }
export const DEFAULT_CARD_LINK_LTD = { com: 0, es: 0, org: 0, eu: 0, mx: 0 }
export const DEFAULT_CARD_LINK_PLATFORM = { blog: 0, cms: 0, ecommerce: 0, news: 0, other: 0 }
export const DEFAULT_CARD_LINK_TYPES = { anchor: 0, image: 0, redirection: 0, alternative: 0, other: 0 }

export const GOOGLE_RUN_PAGESPEED_API_KEY = 'AIzaSyCYKb-awT7XcUHXaCuFia7ZiW7qkPsCvi8'
