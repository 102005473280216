import './Invoices.scss'
import InvoicesModel from './InvoicesModel'
import { useTranslation } from 'react-i18next'
import { Grid2, Tooltip, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_CRM_INVOICES } from '../../../utils/TableColumnsConstants'
import InvoicesController from './InvoicesController'
import { useEffect } from 'react'

const InvoicesView = () => {
  const { t } = useTranslation()

  const model = InvoicesModel()
  const controller = InvoicesController(model)

  useEffect(() => {
    controller.handleSearchBtn(null)
  }, [])

  return (
    <Grid2 container spacing={2} className='containerInvoices'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Tooltip title={t('routes.billings')} followCursor arrow>
            <Typography className='titleName'>{t('routes.billings')}</Typography>
          </Tooltip>

          <TableComponent
            getRowHeight={() => 'auto'}
            rows={model.invoicesData}
            loading={model.isLoading.invoices}
            columns={COLUMNS_CRM_INVOICES(t)}
            paginationMode='client'
            sortingMode='client'
            className='fullHeight'
          />

        </PaperItem>
      </Grid2>
    </Grid2>
  )
}
export default InvoicesView


/*
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../../hooks/useUsers';
import { useAccessToken } from '../../../hooks/useAccessToken';
import { useColorModeContext } from '../../../context/ColorModeContext';
import { classNames, generateIcon, getBaseUrl } from '../../../utils/Utils';
import { SERVICE_CRM_GET_CUSTOMER_INVOICES } from '../../../services/Endpoint';

import {
  COLUMNS_ACCOUNT_INVOICES
} from '../../../utils/TableColumnsConstants';

import { Stack, Button, Typography, CircularProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import TableComponent from '../../../components/TableComponent';

export const Invoices = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { mode } = useColorModeContext();
  const { error, invoices, loadingInvoices, fetchGetInvoice } = useUsers();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleDownload = async (invoiceId: string) => {
    try {
      if (!token) {
        throw new Error('Token not available');
      }

      setLoadingIds((prev) => [...prev, invoiceId]);

      await delay(100);

      const url = `${getBaseUrl()}${SERVICE_CRM_GET_CUSTOMER_INVOICES}/${invoiceId}`;
      const response = await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const contentDisposition = response.headers['content-disposition'];
      let filename = `${invoiceId}.pdf`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }
      }

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo', error);
    } finally {
      setLoadingIds((prev) => prev.filter((id) => id !== invoiceId));
    }
  };

  const COLUMNS_CONNECTIONS: GridColDef[] = useMemo(
    () => [
      ...COLUMNS_ACCOUNT_INVOICES(t),
      {
        field: 'download',
        headerName: t('headerName.download'),
        description: t('tooltip.download'),
        sortable: false,
        align: 'center',
        pinnable: false,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
          <Button
            color="primary"
            onClick={() => handleDownload(params.row.id)}
            disabled={loadingIds.includes(params.row.id)}
          >
            {loadingIds.includes(params.row.id) ? (
              <CircularProgress size={24} />
            ) : (
              generateIcon('Download')
            )}
          </Button>
        )
      }
    ],
    [t, token, loadingIds]
  );

  useEffect(() => {
    if (token) {
      fetchGetInvoice();
    }
  }, [token, fetchGetInvoice]);

  return (
    <Stack>
      <h1>{t('routes.billings')}</h1>
      <TableComponent
        hideFooter
        rows={invoices}
        loading={loadingInvoices}
        disableRowSelectionOnClick
        checkboxSelectionVisibleOnly
        columns={COLUMNS_CONNECTIONS}
        className={classNames('table-connections', mode)}
        slots={{
          noResultsOverlay: () => (
            <Stack className="connections-info">
              <Typography>{error?.message}</Typography>
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack className="connections-info">
              {error ? (
                <Typography>{error?.message}</Typography>
              ) : (
                <Typography>{t('no_billing_connected')}</Typography>
              )}
            </Stack>
          )
        }}
      />
    </Stack>
  );
};
*/
