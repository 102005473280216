import RestDomainController from '../../../hooks/RestDomainController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useCallback } from 'react';
import { parseStringToArray } from '../../../utils/Utils';

export const BacklinksController = (model) => {

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country } = useSearchContext();

  // functions

  // Applu params when any of the filter changes
  const onFilter = (name, from, to, action) => {
    model.setFilter((prevItems) => {
      const newItems = new Map([...prevItems]);

      // Initialize an object to store filter values directly
      const payload = {};

      if (action === "delete") {
        // Remove the filter keys from the Map
        newItems.delete(`${name}From`);
        newItems.delete(`${name}To`);
      } else {
        // Add or update the filters in the Map, storing only the raw values
        if (from) newItems.set(`${name}From`, from);
        if (to) newItems.set(`${name}To`, to);
      }

      // Convert the Map into an object with only raw values
      newItems.forEach((value, key:any) => {
        payload[key] = value; // Store only the value, no extra object
      });

      // Update model state (if needed)
      model.setFilter(newItems);

      return new Map(newItems); // Ensure React detects changes
    });
  };

  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { domain: inputValue, country: country };

    model.setIsLoading((prev) => ({ ...prev, search: true }))
    model.setIsLoading((prev) => ({ ...prev, backlinks: true }))
    params['limit'] = 10
    rest({ params, endpoint: 'getDomainBacklinks'})
      .then((data) => {
        model.setBackLinksData(data.response);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, backlinks: false }))
        model.setIsLoading((prev) => ({ ...prev, search: false }))
      })

  }, [inputValue, country, model.keywordsData, model.keywordsOverviewData, model.searchIntentData]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }

  const getCleanLtd = () => {
    const domains = model.backLinksData?.linksLtd

    if(!domains){
      return
    }

    const tldCounts = {};
    Object.entries(domains).forEach(([domain, count]) => {
      const parts = domain.split(".");
      const tld = parts.length > 1 ? parts[parts.length - 1] : domain; // Extract TLD

      tldCounts[tld] = (tldCounts[tld] || 0) + count; // Sum values for duplicate TLDs
    });

    return tldCounts;
  }


  return {
    handleSearchBtn,
    handleCountryUpdate,
    onFilter,
    getCleanLtd
  }
}

