import { Chip } from '@mui/material';
import { generateIcon } from '../../utils/Utils';
import KeywordRedirectLink from '../KeywordRedirectLink';
import { useTranslation } from 'react-i18next'
import KeywordLinkBlank from '../KeywordLinkBlank'

interface Props {
  data: {
    page_from_title: string
    url_from: string
    url_to_https: string
    page_from_language: string
    domain_from_platform_type: any
  };
  code: number;
  link?: string;
  path: string;
}

const BacklinkPageFromTitle = ({ data, code, link, path }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        { data.isNew && <Chip label={t('isNew')} size="small" className='isNew' /> }
        { data.isLost && <Chip label={t('isLost')} size="small" className='isLost' /> }
        { data.isFollow==='false'?<Chip label={t('isNoFollow')} size="small" className='isNoFollow' />:'' }
        { data.isBroken && <Chip label={t('isBroken')} size="small" className='isBroken' /> }
      </div>
      <div className="flex flex-col py-2">
        <KeywordLinkBlank href={data.sourceUrl} />
      </div>
    </>
  );
};

export default BacklinkPageFromTitle;
