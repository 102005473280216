import { useCallback, useEffect } from 'react'
import RestCrmController from '../../../hooks/RestCrmController'

const InvoicesController = (model) => {

  // hooks
  const { rest } = RestCrmController();

  // functions

  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = {  };

    model.setIsLoading((prev) => ({ ...prev, invoices: true }))
    rest({ params, endpoint: 'getCustomerInvoices'})
      .then((data) => {
        model.setInvoicesData(data.items);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, invoices: false }))
      })

  }, []);


  return {
    handleSearchBtn
  }
}

export default InvoicesController;