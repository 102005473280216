import { formatCurrencyByLocation } from '../../utils/Utils';

interface Props {
  value: number
  currency: string
}

const cost = ({ value, currency }: Props) => {
  return (
      formatCurrencyByLocation(value, currency)
  );
};

export default cost;