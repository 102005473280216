import RestKeywordController from '../../../hooks/RestKeywordController';
import { useCallback, useEffect } from 'react';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/Constants';

const KeywordExplorerController = (model) => {

  // hooks
  const { rest } = RestKeywordController();
  const { inputValue, country, setCountry } = useSearchContext();
  const navigate = useNavigate();

  // functions


  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { keywords: inputValue };

    model.setIsLoading((prev) => ({ ...prev, search: true }))

    model.setIsLoading((prev) => ({ ...prev, topCountries: true }));
    const topCountriesPromise = rest({ params, endpoint: 'getTopCountriesByKeyword'})
      .then((data) => {
        model.setTopCountries(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, topCountries: false }))
      })

    params['country'] = country
    params['mode'] = 'related'
    params['limit'] = 100
    model.setIsLoading((prev) => ({ ...prev, keywordOverview: true }))
    const keywordOverviewPromise = rest({ params, endpoint: 'getKeywordOverview' })
      .then((data) => {
        model.setKeywordsOverviewData(data.keywords);
        model.setMetrics(data.keywords.items)
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, keywordOverview: false }))
      })

    model.setIsLoading((prev) => ({ ...prev, keywords: true }));
    params['limit'] = 10
    const keywordsPromise = rest({ params, endpoint: 'getKeywords'})
      .then((data) => {
        model.setKeywordsData(data.keywords);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, keywords: false }))
      })

    // wait until all promises finished to restore search loading
    Promise.allSettled([keywordOverviewPromise, keywordsPromise, topCountriesPromise]).then(() => {
      model.setIsLoading((prev) => ({ ...prev, search: false }))
    });

  }, [inputValue, country, model.keywordsData, model.keywordsOverviewData]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }


  // Redirect to Keyword Ideas Screen to view more keywords
  const redirectToIdeas = useCallback(() => {
      navigate(`${ROUTES.IDEAS}?q=${inputValue}&country=${country}`);
  }, [location, navigate, inputValue, country, model.endpoints]);



  const handleOnClickCardListItem = (e) => {
    if(!inputValue){
      return
    }
    const location = e.currentTarget.children.item('flag').children.item('img').alt
    setCountry(location)
    navigate(`${ROUTES.EXPLORE}?q=${inputValue}&country=${location}`);
  };


  return {
    handleSearchBtn,
    handleCountryUpdate,
    redirectToIdeas,
    handleOnClickCardLitItem: handleOnClickCardListItem
  }
}

export default KeywordExplorerController