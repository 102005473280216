import { DomainGeneralModel } from './DomainGeneralModel'
import { DomainGeneralController } from './DomainGeneralController'
import { Box, CircularProgress, Grid2, Tooltip, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'
import DomainMetadata from '../../../components/Domain/DomainMetadata/DomainMetadata'
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'
import BarChartTop from '../../../components/Recharts/BarChartTop/BarChartTop'
import KeywordByIntent from '../../../components/KeywordByIntent/KeywordByIntents'
import TableComponent from '../../../components/TableComponent'
import BubbleChart from '../../../components/BubbleChart/BubbleChart'
import {
  COLUMNS_DOMAIN_BEST_PAGES,
  COLUMNS_DOMAIN_COMPETITORS_KEYWORDS,
  COLUMNS_DOMAIN_KEYWORDS
} from '../../../utils/TableColumnsConstants'
import { DEFAULT_CARD_LIST_COUNTRY, DOMAIN_BUBBLE_CHART_COLUMNS } from '../../../utils/Constants'
import ViewMoreButton from '../../../components/ViewMoreButton'
import { formatNumber } from '../../../utils/Utils'
import ButtonExportExcel from '../../../components/Buttons/ExportExcel'
import CarList from '../../../components/CardList/CarList'

export const DomainGeneralView = () => {
  const { t } = useTranslation()

  const model = DomainGeneralModel()
  const controller = DomainGeneralController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 4, md: 4 }}>
        <PaperItem className='boxShadow' sx={{ p: 3, height: '100%' }}>
          <DomainMetadata data={model.keywordsOverviewData} />
        </PaperItem>
      </Grid2>

      <Grid2 className='container-cart-date' size={{ xs: 12, sm: 4, md: 4  }}>
        <PaperItem className='boxShadow' sx={{ p: 2, height: '100%' }}>
          <Tooltip title={t('tooltip.totalKeywords')} followCursor arrow>
            <Typography className='title'>{t('totalKeywords')}</Typography>
          </Tooltip>
          <Typography className='enfatized'>{formatNumber(Object.values(model.topCountriesByKeyword.items || {}).reduce((acc, num) => acc + num, 0))}</Typography>
          <CarList items={model.topCountriesByKeyword.items} defaultValues={DEFAULT_CARD_LIST_COUNTRY} onClick={controller.handleOnClickCardListItem} linkEnabled isFlag />
        </PaperItem>
      </Grid2>

      <Grid2 className='container-cart-date' size={{ xs: 12, sm: 4, md: 4 }}>
        <PaperItem className='boxShadow' sx={{ p: 2, height: '100%' }}>
          <Tooltip title={t('tooltip.totalTraffic')} followCursor arrow>
            <Typography className='title'>{t('totalTraffic')}</Typography>
          </Tooltip>
          <Typography className='enfatized'>{formatNumber(Object.values(model.topCountriesByTraffic.items || {}).reduce((acc, num) => acc + num, 0))}</Typography>
          <CarList items={model.topCountriesByTraffic.items} defaultValues={DEFAULT_CARD_LIST_COUNTRY} onClick={controller.handleOnClickCardListItem} linkEnabled isFlag />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
        <PaperItem className='boxShadow' sx={{ p: 3, height: '100%' }}>
          <div className='flex-row gap-1'>
            <CustomTooltip content={t('tooltip.ranking_distribution')}>
              <Typography fontWeight={700} variant='h5'>
                {t('ranking_distribution')}
              </Typography>
            </CustomTooltip>
            <div className='spacer' />
          </div>
          <BarChartTop loading={model.isLoading.ranges} data={model.rankRangesData} />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
        <PaperItem className='boxShadow' sx={{ p: 3, height: '100%' }}>
          <div>
            <CustomTooltip content={t('tooltips.domains.overview_keywords_intent')}>
              <Typography fontWeight={700} variant='h5'>
                {t('keyword_by_intent')}
              </Typography>
            </CustomTooltip>
            <div className='spacer' />
          </div>
          <KeywordByIntent intentsData={model.searchIntentData} loading={model.isLoading.searchIntent} />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <div className='flex items-center justify-between'>
            <CustomTooltip content={t('tooltip.organic_keywords')}>
              <Typography>{t('organic_keywords')}</Typography>
            </CustomTooltip>
            <Box
              sx={{
                width: 120,
                height: 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ButtonExportExcel
                isLoading={model.isLoading.export}
                onClick={controller.handleExportBtn}
                totalKeywords={model.domainData?.totalKeywords || 0}
              />
            </Box>
          </div>
          <TableComponent
            hideFooter
            rows={model.domainData?.items}
            loading={model.isLoading.domain}
            getRowHeight={() => 'auto'}
            columns={COLUMNS_DOMAIN_KEYWORDS(t, {
              code: location
            })}
            paginationMode='client'
            sortingMode='client'
            hideFooterPagination
            className='fullHeight'
          />
          <ViewMoreButton onClick={controller.redirectToKeywords} disabled={!model.domainData}>
            {`${t('view')} ${formatNumber(model.domainData?.totalKeywords)} ${t('more_keywords').toLocaleLowerCase()}`}
          </ViewMoreButton>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <CustomTooltip content={t('tooltip.bestPages')}>
            <Typography>{t('bestPages')}</Typography>
          </CustomTooltip>
          <TableComponent
            hideFooter
            rows={model.bestPages?.items}
            loading={model.isLoading.bestPages}
            getRowHeight={() => 'auto'}
            columns={COLUMNS_DOMAIN_BEST_PAGES(t, {
              code: location
            })}
            paginationMode='client'
            sortingMode='client'
            hideFooterPagination
            className='fullHeight'
          />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
        <PaperItem className='boxShadow' sx={{ p: 3, height: '100%' }}>
          <div className='flex-row gap-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <CustomTooltip content={t('tooltip.main_competitors')}>
              <Typography fontWeight={700} variant='h5'>
                {t('main_competitors')}
              </Typography>
            </CustomTooltip>
            {model.isLoading.competitors && <CircularProgress size={24} style={{ background: 'inherit' }} />}
          </div>

          <div className='spacer' />

          <BubbleChart
            loading={model.isLoading.competitors}
            data={model.competitorsData?.items.slice(0, 10)}
            columns={DOMAIN_BUBBLE_CHART_COLUMNS}
          />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <CustomTooltip content={t('tooltips.domains.overview_organic_competitor')}>
            <Typography className='mb-5'>{t('organic_competitors')}</Typography>
          </CustomTooltip>
          <TableComponent
            hideFooter
            rows={model.competitorsData?.items}
            getRowHeight={() => 'auto'}
            loading={model.isLoading.competitors}
            columns={COLUMNS_DOMAIN_COMPETITORS_KEYWORDS(t, {
              code: location
            })}
            paginationMode='client'
            sortingMode='client'
            hideFooterPagination
            className='fullHeight'
          />
          <ViewMoreButton onClick={controller.redirectToCompetitors} disabled={!model.competitorsData}>
            {`${t('view')} ${formatNumber(model.competitorsData?.total)} ${t('routes.competitors')}`}
          </ViewMoreButton>
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
