import './index.scss'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardContent, Container, Grid2, Typography } from '@mui/material'

import { ROUTES } from '../utils/Constants'
import { generateIcon } from '../utils/Utils'
import SEOAnimation from '../components/Animations/SEOAnimation'

export const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container maxWidth='lg' className='container'>
      <h1 className='title'>{t('welcome-to-rankerfy')}</h1>
      <SEOAnimation />
      <Typography>
        {t('it-allows-you-to-help-find-niche-ideas-and-analyze-domains-and-keywords')}
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <Link
            to={ROUTES.PROJECTS_MANAGE}
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.PROJECTS_MANAGE)}
          >
            <Card className='card home-card'>
              <CardContent>
                {generateIcon('QueryStatsRounded', { className: 'icon' })}
                <Typography variant='h5' component='div' className='card-title'>
                  {t('routes.projects')}
                </Typography>
                <Typography variant='body2' color='textSecondary' className='card-description'>
                  {t(
                    'detect-thin-content-cannibalization-and-opportunity-keywords-by-connecting-your-account-with-gsc-and-running-custom-algorithms'
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <Link
            to={ROUTES.IDEAS}
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.IDEAS)}
          >
            <Card className='card home-card'>
              <CardContent>
                {generateIcon('DiamondRounded', { className: 'icon' })}
                <Typography variant='h5' component='div' className='card-title'>
                  {t('routes.ideas')}
                </Typography>
                <Typography variant='body2' color='textSecondary' className='card-description'>
                  {t(
                    'obtain-related-keyword-suggestions-by-applying-advanced-filters-that-allow-you-to-learn-new-long-tail-keywords'
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <Link
            to={ROUTES.NICHE_KEYWORDS}
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.NICHE_KEYWORDS)}
          >
            <Card className='card home-card'>
              <CardContent>
                {generateIcon('LightbulbRounded', { className: 'icon' })}
                <Typography variant='h5' component='div' className='card-title'>
                  {t('routes.niche-ideas')}
                </Typography>
                <Typography variant='body2' color='textSecondary' className='card-description'>
                  {t('it-allows-you-to-help-find-niche-ideas-and-analyze-domains-and-keywords')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <Link
            to={ROUTES.DOMAIN_COMPARATOR}
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.DOMAIN_COMPARATOR)}
          >
            <Card className='card home-card'>
              <CardContent>
                {generateIcon('CompareArrowsRounded', { className: 'icon' })}
                <Typography variant='h5' component='div' className='card-title'>
                  {t('routes.content-gap')}
                </Typography>
                <Typography variant='body2' color='textSecondary' className='card-description'>
                  {t(
                    'it-allows-you-to-compare-your-domain-or-url-with-those-of-competitors,-analyzing-unique-or-common-keywords'
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid2>
      </Grid2>
    </Container>
  )
}
