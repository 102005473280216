import './BacklinksAnchors.scss'

import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import { Chip, Typography } from '@mui/material'
import KeywordLinkBlank from '../KeywordLinkBlank'
import KeywordRedirectLink from '../KeywordRedirectLink'

const BacklinksAnchors = ( {value}) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col'>
      <Typography>{value.destinationAnchor}</Typography>
      <KeywordRedirectLink link={value.destinationUrl} path='/domain/view/' showIcon />
      {
        /*

      <div className='flex gap-1 items-center'>
        {Array.isArray(value.attributes) && value.attributes.length > 0 && value.attributes.map((item) =>
          <Chip key={item} label={item} />)}
        {value.item_type && <Chip label={value.item_type} />}
        {value.semantic_location && <Chip label={value.semantic_location} />}
        {value.is_new && <Tooltip arrow followCursor title={t('tooltip.is_new')} ><Chip label={t('is_new')} className={'chipNew'} /></Tooltip>}
        {value.is_lost && <Tooltip arrow followCursor title={t('tooltip.is_lost')} ><Chip label={t('is_lost')} className={'chipLost'} /></Tooltip>}
        {value.dofollow!==true && <Tooltip arrow followCursor title={t('tooltip.nofollow')} ><Chip label={t('nofollow')} className={'chipNofollow'} /></Tooltip>}
      </div>
         */


      }
    </div>
  )
}

export default BacklinksAnchors;
