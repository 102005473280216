import './KeywordFeatures.scss'

import { generateUuid, serpIcons } from '../../utils/Utils'
import { Skeleton, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

const KeywordFeatures = ({ data }: { data: string[] }) => {
  const { t } = useTranslation()

  if (!data) return '—';
  else if (data?.length === 0)
    return (
      <div className="flex gap-1">
        {Array.from({ length: 3 }).map(() => (
          <Skeleton key={generateUuid()} variant="circular" width={20} height={20} />
        ))}
      </div>
    );
  return (
    <div className={'keywordFeatures'}>
      {data?.map((item: any) => (
        <Tooltip title={t(item)} arrow followCursor className="serp-features" key={generateUuid()} >
          <Typography>{serpIcons[item]}</Typography>
        </Tooltip>
      ))}
    </div>
  );
};

export default KeywordFeatures;