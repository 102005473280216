import './Invoice.scss'

import { Button, CircularProgress } from '@mui/material'
import CloudDownload from '@mui/icons-material/CloudDownload'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import RestCrmController from '../../hooks/RestCrmController'
import { HttpStatusCode } from 'axios'

interface Props {
  params: {
    id: string
    number: string
    customNumber: string
    paymentId: string
  };
}

const InvoiceDownload = (row: Props) => {
  const { t } = useTranslation()

  // hooks
  const { rest } = RestCrmController();
  const [loading, setLoading] = useState(false);

  const handleDownloadPDF = async (e) => {

    const params = { id: row.params.id };
    setLoading(true);
    rest({ params, endpoint: 'downloadInvoice' })
      .then(async (response) => {
        if (response.status === HttpStatusCode.Ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = `${row.params.customNumber || row.params.number}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          window.URL.revokeObjectURL(url);
        } else {
          console.error('Failed to download PDF', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      })
      .finally(() => {
        setLoading(false);
      })

  }

  return (
    <Button className='invoiceDownload'
      variant="contained"
      color="primary"
      onClick={handleDownloadPDF}
      startIcon={loading ? <CircularProgress size={14} color="inherit" /> : <CloudDownload />}
     >
      {t('headerName.invoiceDownload')}
    </Button>

  )

}

export default InvoiceDownload;