import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { ROUTES } from './utils/Constants'

// MAINS
import { Home } from './pages'
import { Error404 } from './pages/404'
import ErrorPage from './components/Error/ErrorPage'
import Splash from './components/Splash'
import BuyPlanPage from './pages/buy-plan'
import SubscriptionThanksPage from './pages/thanks'
import PlanGuard from './components/PlanGuard/PlanGuard'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { PageContainer } from './components/Essential/PageContainer'

// PROJECTS-PAGE
import { ProjectsManagePage } from './pages/projects/manage'
import { ProjectsThinContentPage } from './pages/projects/thin_content'
import CannibalizationView from './pages/projects/cannibalization/cannibalizationView'
import { ProjectsPerformanceView } from './pages/projects/performance/ProjectsPerformanceView'
import { ProjectDetail } from './pages/projects/detail/ProjectDetail'
import OpportunityKeywordsView from './pages/projects/opportunityKeywords/opportunityKeywordsView'

// KEYWORD-PAGE
import { KeywordIdeasView } from './pages/keywords/ideas/KeywordIdeasView'
import { KeywordExplorerView } from './pages/keywords/explorer/KeywordExplorerView'
import { BatchAnalysisPage } from './pages/keywords/batch/KeywordBatchView'

// DOMAIN-PAGE
import { DomainGeneralView } from './pages/domain/general/DomainGeneralView'
import { ContentGapView } from './pages/domain/contentGap/ContentGapView'
import { BacklinksView } from './pages/domain/backlinks/BacklinksView'
import { CompetitorsView } from './pages/domain/competitors/CompetitorsView'
import { PositionsView } from './pages/domain/positions/PositionsView'
import { DomainBatchView } from './pages/domain/batch/DomainBatchView'
import { NicheDomainsView } from './pages/niche/domains/NicheDomainsView'

// ACCOUTS-PAGE
import { AccountConnectionsPage } from './pages/account/connections'
import { AccountMessingAroundPage } from './pages/account/messing-around'
import { AccountContainer } from './components/Essential/AccountContainer'
import SupportView from './pages/account/Support/supportView'
import { NicheKeywordViews } from './pages/niche/keywords/NicheKeywordViews'
import InvoicesView from './pages/account/invoices/InvoicesView'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer>
            <Home />
          </PageContainer>
        </PlanGuard>
      </ScrollToTop>
    ),
    errorElement: <Error404 />
  }, {
    path: '/error',
    element: <ErrorPage />
  },
  {
    path: ROUTES.BUY_PLAN_USER,
    element: <BuyPlanPage />
  },
  {
    path: ROUTES.THANKS,
    element: (
      <SubscriptionThanksPage />
    )
  },
  {
    path: ROUTES.ACCOUNT,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <AccountContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.ACCOUNT_CONNECTIONS} />
      },
      {
        path: 'connections/',
        element: <AccountConnectionsPage />
      },
      {
        path: 'billings/',
        element: <InvoicesView />
      },
      {
        path: 'messing-around/',
        element: <AccountMessingAroundPage />
      },
      {
        path: 'support/',
        element: <SupportView />
      }
    ]
  },
  {
    path: ROUTES.PROJECTS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.PROJECTS_MANAGE} />
      },
      {
        path: 'manage/',
        element: <ProjectsManagePage />
      },
      {
        path: 'cannibalization/',
        element: <CannibalizationView />
      },
      {
        path: 'thin-content/',
        element: <ProjectsThinContentPage />
      },
      {
        path: 'opportunity-keywords/',
        element: <OpportunityKeywordsView />
      },
      {
        path: 'performance/',
        element: <ProjectsPerformanceView />
      },
      {
        path: 'performance/detail/:domain',
        element: <ProjectDetail />
      }
    ]
  },
  {
    path: ROUTES.KEYWORDS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.EXPLORE} />
      },
      {
        index: true,
        path: 'explorer/',
        element: <KeywordExplorerView />
      },
      {
        path: 'ideas/',
        element: <KeywordIdeasView />
      },
      {
        path: 'bulk-analysis/',
        element: <BatchAnalysisPage />
      }
    ]
  },
  {
    path: ROUTES.DOMAINS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.DOMAIN_VIEW} />
      },
      {
        index: true,
        path: 'view/',
        element: <DomainGeneralView />
      },
      {
        path: 'bulk-analysis/',
        element: <DomainBatchView />
      },
      {
        path: 'keywords/',
        element: <PositionsView />
      },
      {
        path: 'competitors/',
        element: <CompetitorsView />
      },
      {
        path: 'backlinks/',
        element: <BacklinksView />
      },
      {
        path: 'content-gap/',
        element: <ContentGapView />
      }
    ]
  },
  {
    path: ROUTES.NICHE_IDEAS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        path: 'keywords/',
        element: <NicheKeywordViews />
      },
      {
        path: 'domains/',
        element: <NicheDomainsView />
      }
    ]
  }
])

export default function Routes () {
  return <RouterProvider router={router} fallbackElement={<Splash />} />
}
