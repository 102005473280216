import { useTranslation } from 'react-i18next';
import { Grid2, Typography } from '@mui/material';
import PaperItem from '../../../components/Essential/PaperItem';
import Search from '../../../components/Search';
import { NicheDomainsModel } from './NicheDomainsModel';
import { NicheDomainsController } from './NicheDomainsController';
import Filter from '../../../components/Filter/Filter';
import TableComponent from '../../../components/TableComponent';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';
import { COLUMNS_DOMAIN_IDEAS_OVERVIEW } from '../../../utils/TableColumnsConstants';
import { FILTER_INTENTION, FILTER_TLD, FILTER_URLS, FILTER_VOLUME } from '../../../utils/Constants';

export const NicheDomainsView = () => {
  const { t } = useTranslation();

  const model = NicheDomainsModel()
  const controller = NicheDomainsController(model)

  return (
    <Grid2 container spacing={2} className="container-analysis">
      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="flex-col gap-2 boxShadow" sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_keyword')}
            loading={model.isLoading.search}
            onSearch={()=> controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
          <div className="flex gap-2 w-full pt-3">
            <Filter name={'tld'} items={FILTER_TLD} custom={false} onChange={controller.onFilter}  />
            <Filter name={'urls'} items={FILTER_URLS} custom={true} onChange={controller.onFilter}  />
            <Filter name={'trafficCost'} items={FILTER_VOLUME} custom={true} onChange={controller.onFilter}  />
            <Filter name={'traffic'} items={FILTER_VOLUME} custom={true} onChange={controller.onFilter}  />
            <Filter name={'keywordsCount'} items={FILTER_VOLUME} custom={false} onChange={controller.onFilter}  />
          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex flex-col gap-2 mb-5">
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('domain_ideas')}</Typography>
            </CustomTooltip>
          </div>
          <TableComponent
            loading={model.isLoading.keywords}
            rows={model.keywordsData?.items}
            columns={COLUMNS_DOMAIN_IDEAS_OVERVIEW(t)}
            paginationMode="client"
            sortingMode="client"
            hideFooter
            className={'fullHeight'}
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}

/*
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useDomainsData } from '../../hooks/useDomainsData';
import { COUNTRY_CODE_ES, topLevelDomainOptions } from '../../utils/Constants';
import { COLUMNS_DOMAIN_IDEAS_OVERVIEW } from '../../utils/TableColumnsConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';
import MenuSelector from '../../components/MenuSelector';
import { OptionsType } from '../../types/common.type';
import { SearchCacheProvider, useSearchCache } from '../../context/SearchCacheContext';
import DomainFilters from '../../components/Filters/Domain/DomainFilters';
import Search from '../../components/Search';

const DOMAIN_CACHE_CONTEXT = 'domain';
const KEYWORD_CACHE_CONTEXT = 'keyword';
const DOMAIN_CACHE_KEY = 'domainList';
const KEYWORD_CACHE_KEY = 'keywordList';
const LOCATION_CACHE_KEY = 'location';
const FILTERS_STORAGE_KEY = 'domain_ideas_filters';

const ContainerPage = () => {
  const { t } = useTranslation();

  const token = useAccessToken();
  const [tldOption, setTldOption] = useState<OptionsType | undefined>();
  const [domains, setDomains] = useState<Array<string>>([]);
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [location, setLocation] = useState<number>(COUNTRY_CODE_ES);
  const [editorDomainValue, setEditorDomainValue] = useState<string>('');
  const [editorKeywordsValue, setEditorKeywordsValue] = useState<string>('');

  const { domainIdeas, fetchDomainIdeas, loadingIdeas, fetchDomainIdeasWithQueries } = useDomainsData('ideas');
  const { handleSearchSpecificFilters } = useKeywordsFiltersContext();
  const { getSearchCache, updateSearchCache, getCountry, updateCountry } = useSearchCache();

  useEffect(() => {
    const cachedDomainData = getSearchCache(`${DOMAIN_CACHE_CONTEXT}_${DOMAIN_CACHE_KEY}`);
    const cachedKeywordData = getSearchCache(`${KEYWORD_CACHE_CONTEXT}_${KEYWORD_CACHE_KEY}`);
    const cachedLocationData = getCountry(LOCATION_CACHE_KEY);

    if (cachedDomainData) setEditorDomainValue(cachedDomainData);
    if (cachedKeywordData) setEditorKeywordsValue(cachedKeywordData);
    if (cachedLocationData) {
      setLocation(Number(cachedLocationData));
    }
  }, [getSearchCache, getCountry]);

  const refreshData = useCallback(async (domainList: Array<string>, keywordList: Array<string>) => {
    const data = {
      location,
      domain: [],
      keywords: keywordList,
      domain_negative: domainList,
      tld: tldOption?.value,
      ...handleSearchSpecificFilters()
    };
    await fetchDomainIdeas(data);
  }, [location, tldOption, fetchDomainIdeas, handleSearchSpecificFilters]);

  const handleSearchBtn = useCallback(async (event: string) => {

    if (event === undefined) {
      return;
    }

    const keywords = event.split(/[\s,]+/).filter(Boolean);
    const negativeKeywords = keywords.filter(keyword => keyword.startsWith('-')).map(keyword => keyword.substring(1));
    const positiveKeywords = keywords.filter(keyword => !keyword.startsWith('-'));

    setDomains(negativeKeywords);
    setKeywords(positiveKeywords);
    updateSearchCache(`${DOMAIN_CACHE_CONTEXT}_${DOMAIN_CACHE_KEY}`, editorDomainValue);
    updateSearchCache(`${KEYWORD_CACHE_CONTEXT}_${KEYWORD_CACHE_KEY}`, editorKeywordsValue);

    await refreshData(positiveKeywords, negativeKeywords);
  }, [editorDomainValue, editorKeywordsValue, refreshData, updateSearchCache]);

  const handleLocationChange = (val: number) => {
    setLocation(val);
    updateCountry(LOCATION_CACHE_KEY, val);
  };

  useEffect(() => {
    fetchDomainIdeasWithQueries();
  }, [token, fetchDomainIdeasWithQueries]);

  useEffect(() => {
    if (domains.length > 0 || keywords.length > 0) refreshData(domains, keywords);
  }, [domains, keywords, tldOption, handleSearchSpecificFilters]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="flex-col gap-2 boxShadow" sx={{ p: 3 }}>

          <Search
            placeholder={t('search_placeholder_domain_ideas')}
            loading={loadingIdeas}
            onSearch={(event) => handleSearchBtn(event)}
            onCountry={handleLocationChange}
          />

          <div className="flex gap-2 w-full items-center">
            <MenuSelector
              value={tldOption}
              isSelected={false}
              suggestion={topLevelDomainOptions}
              defaultValue={tldOption?.label ?? 'TLD'}
              onChange={(values) => setTldOption(values)}
            />
            <DomainFilters />
          </div>
        </PaperItem>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex flex-col gap-2 mb-5">
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('domain_ideas')}</Typography>
            </CustomTooltip>
          </div>
          <TableComponent
            loading={loadingIdeas}
            rows={domainIdeas.items}
            hideFooterPagination={loadingIdeas}
            columns={COLUMNS_DOMAIN_IDEAS_OVERVIEW(t, { code: location })}
            paginationMode="client"
            sortingMode="client"
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const DomainsIdeasPage: React.FC = () => (
  <KeywordsFiltersProvider storageKey={FILTERS_STORAGE_KEY}>
    <SearchCacheProvider>
      <ContainerPage />
    </SearchCacheProvider>
  </KeywordsFiltersProvider>
);

 */

