import { Typography } from '@mui/material'

const ResultRankAbsolute = ({ position }) => {
  return (
    <div>
      <Typography>#{position}</Typography>
    </div>
  )
}

export default ResultRankAbsolute
