import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import CloudDownload from '@mui/icons-material/CloudDownload';

interface Props {
  isLoading: boolean;
  totalKeywords?: number;
  query: string;
  onClick: (format: string) => void;
}

const ButtonExportExcel: React.FC<Props> = ({ isLoading, onClick, totalKeywords, query }) => {
  const primaryColor = '#6b5b95';

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading || !totalKeywords}
      onClick={() => onClick('generic')}
      startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <CloudDownload />}
      sx={{
        backgroundColor: primaryColor,
        '&:hover': {
          backgroundColor: '#5a4a85'
        },
        color: '#fff',
        mb: 2
      }}>
      Export
    </Button>
  );
};

export default ButtonExportExcel;
