import './Filter.scss';
import { useTranslation } from 'react-i18next';

import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { Button, ClickAwayListener, Divider, Tooltip, Popper, TextField, MenuItem, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatNumber } from '../../utils/Utils';
import CloseIcon from '@mui/icons-material/Close';
import { FilterInterface } from '../../interfaces/FilterInterface';
import { FilterInterfaceItem } from '../../interfaces/FilterInterfaceItem';


const Filter: FC<FilterInterface> = ({ name, items, custom, onChange } ) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [from, setFrom] = useState<number | undefined>(null);
  const [to, setTo] = useState<number | undefined>(null);
  const [selected, setSelected] = useState<string | undefined>('')

  const handleToggle = (event: MouseEvent<HTMLElement>, e) => {
    if(event.target.attributes.name===undefined) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else if (event.target.attributes.name.value==='delete') {
      onChange(name, 0, 0, 'delete');
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = (item: FilterInterfaceItem) => {
    if (item.type === "check") {
      setSelected(t(`filter${item.label}`) ?? "");
      onChange(name, undefined, undefined, item.key);
    } else {
      setFrom(item.from);
      setTo(item.to);
      setInputValue(`${item.from ?? ""} - ${item.to ?? ""}`);
      onChange(name, item.from, item.to);
    }
    handleClose();
  };

  const handleApply = () => {
    onChange(name, from, to);
    setInputValue(`${from ?? ""} - ${to ?? ""}`);
    handleClose();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    const match = value.match(/(\d+)?\s*-\s*(\d+)?/);
    if (match) {
      setFrom(match[1] ? parseInt(match[1]) : undefined);
      setTo(match[2] ? parseInt(match[2]) : undefined);
    }
  };

  const handleClearValues = () =>{
    setFrom(undefined)
    setTo(undefined)
    setSelected('')
    setInputValue('');
    handleClose();
  }

  return (
    <>
      <div onClick={handleToggle} className={`filter ${from || to || selected ?'selected':''  }`}>
        <span className={'left'}>{t(name)}</span>
        <div className={'right'}>
          <TextField
            className={'customInput'}
            name={'input'}
            value={inputValue || selected}
            onChange={handleInputChange}
            variant="standard"
            autoComplete="off"
            onBlur={handleClose}
            slotProps={{
              input: {
                disableUnderline: true,
                fullWidth: true,
                className: 'customInput',
                readOnly: items.some(item => item.type === "check")
              }
            }}

          />
          {
            from||to||selected?(<CloseIcon onClick={handleClearValues} name={'delete'} />):(<KeyboardArrowDownIcon />)
          }
        </div>
      </div>
      <Popper open={open} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <div className={"filterContainer"}>
            {items.map((item, index) => (

              /*
<MenuItem key={index} onClick={() => handleSelect(item)} style={{ fontFamily: "monospace" }}>
  {item.type === "check" ? (
    t(`filter${item.label}`)
  ) : (
    <div style={{ display: "flex", justifyContent: "space-between", width: "100px" }}>
      <span style={{ minWidth: "45px", textAlign: "right" }}>{formatNumber(item.from) ?? ""}</span>
      <span> - </span>
      <span style={{ minWidth: "45px", textAlign: "left" }}>{item.to !== undefined ? formatNumber(item.to) : ""}</span>
    </div>
  )}
</MenuItem>
               */


                <MenuItem key={index} onClick={() => handleSelect(item)} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }} >
                  {item.type === "check"
                    ? t(`filter${item.label}`)
                    :(
                      <>
                        <div>
                          {t(item.prepend)}
                          {
                            item.tooltip &&
                            <Tooltip title={t(item.tooltip)} arrow followCursor >
                              <Box
                                component="span"
                                sx={{
                                  ml: 0.5,
                                  fontSize: '8px',
                                  lineHeight: 1,
                                  verticalAlign: 'super',
                                  color: 'gray',
                                  cursor: 'pointer',
                                  display: 'inline-block',
                                }}
                              >
                                i
                              </Box>
                            </Tooltip>
                          }
                        </div>
                        <div className='item'>
                          <div className='right'>{formatNumber(item.from) ?? ""}</div>
                          <div>-</div>
                          <div className='left'>{item.to !== undefined ? formatNumber(item.to) : ''}{item.append}</div>
                        </div>
                      </>
                    )
                  }
                </MenuItem>

            ))}
            {custom && (
              <>
                {items.length>0 &&
                  <Divider className={'divider'} />
                }
                <div className={'customRange'}>
                  {t('custom_range')}
                </div>
                <div style={{ display: "flex", gap: "8px", marginTop: "5px" }}>
                  <TextField
                    type="number"
                    value={from ?? ""}
                    onChange={(e) => setFrom(parseInt(e.target.value) || undefined)}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    type="number"
                    value={to ?? ""}
                    onChange={(e) => setTo(parseInt(e.target.value) || undefined)}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleApply}
                  style={{ marginTop: "10px" }}
                >
                  {t('apply')}
                </Button>
              </>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Filter;