import { Grid2, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'
import { ContentGapModel } from './ContentGapModel'
import { ContentGapController } from './ContentGapController'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_DOMAIN_CONTENT_GAP } from '../../../utils/TableColumnsConstants'
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup'
import { ALL_FILTER_MODE_CONTENT_GAP } from '../../../utils/Constants'

export const ContentGapView = () => {
  const { t } = useTranslation()

  const model = ContentGapModel()
  const controller = ContentGapController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_contentGap')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
            lines={4}
            maxLines={4}
          />
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Typography className='mb-5'>{t('routes.content-gap')}</Typography>
          <TableComponent
            rows={model.keywordsData?.[model.modeFilter]}
            loading={model.isLoading.keywords}
            columns={COLUMNS_DOMAIN_CONTENT_GAP(t, {
              domains: model.domains
            })}
            columnVisibilityModel={controller.columnVisibilityModel}
            paginationMode='client'
            sortingMode='client'
            slots={{
              toolbar: () => (
                <div className='py-2'>
                  <ButtonGroup
                    current={model.modeFilter}
                    onChange={model.setModeFilter}
                    suggestions={ALL_FILTER_MODE_CONTENT_GAP}
                  />
                </div>
              )
            }}
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
