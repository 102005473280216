import { getBaseUrl } from '../utils/Utils'
import BaseService from './BaseService'
import * as ENDPOINT from './Endpoint'
import { SERVICE_DOMAINS_GET_TOP_COUNTRIES_BY_KEYWORDS, SERVICE_DOMAINS_GET_TOP_COUNTRIES_BY_TRAFFIC } from './Endpoint'
import {
  DomainBacklinkRequest,
  DomainBulkRequest,
  DomainBulkResponse,
  DomainIdeasRequest,
  DomainIdeasResponse,
  DomainOverview,
  DomainOverviewRequest,
  DomainRankRanges,
  DomainRankRangesRequest,
  DomainsContentGapRequest,
  DomainSearchIntents,
  DomainSearchIntentsRequest,
  DomainsKeywordRequest,
  KeywordSuggestionsRequest
} from '../types'

import ContentGapDto from './dtos/domainsContentGap.dto'
import DomainsKeywordDto from './dtos/domainsKeyword.dto'
import DomainsBacklinksDto from './dtos/domainsBacklinks.dto'
import DomainsOverviewHistoryDto from './dtos/domainsOverviewHistory.dto'
import KeywordMetricsDto from './dtos/keywordsMetrics.dto'

class DomainService extends BaseService {
  async getKeywords(params: DomainsKeywordRequest): Promise<DomainsKeywordDto> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_KEYWORDS}`;

    const response = await this.post(url, data, accessToken);
    return new DomainsKeywordDto(response.data);
  }

  async getCompetitors(params: DomainsKeywordRequest): Promise<DomainBulkResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_COMPETITORS}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getDomainOverview(params: DomainOverviewRequest): Promise<DomainOverview> {
    const { country, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_OVERVIEW}`;
    const data = { country, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getDomainBacklinks(params: DomainBacklinkRequest): Promise<DomainsBacklinksDto> {
    const { country, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_BLACKLINKS}`;
    const data = { country, domain };

    const response = await this.post(url, data, accessToken);
    return new DomainsBacklinksDto(response.data);
  }

  async getDomainOverviewHistory(
    params: DomainsKeywordRequest
  ): Promise<DomainsOverviewHistoryDto> {
    const { country, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_OVERVIEW_HISTORY}`;
    const data = { country, domain };

    const response = await this.post(url, data, accessToken);
    return new DomainsOverviewHistoryDto(response.data);
  }

  async getContentGap(params: DomainsContentGapRequest): Promise<ContentGapDto> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_CONTENT_GAP}`;

    const response = await this.post(url, data, accessToken);
    return new ContentGapDto(response.data);
  }

  async getSearchIntents(params: DomainSearchIntentsRequest): Promise<DomainSearchIntents> {
    const { domain, country, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_INTENTS}`;
    const data = { country, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getRankRanges(params: DomainRankRangesRequest): Promise<DomainRankRanges> {
    const { domain, country, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_RANK_RANGES}`;
    const data = { country, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getBulkOverview(params: DomainBulkRequest): Promise<DomainBulkResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_BULK_OVERVIEW}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getIdeaOverview(params: DomainIdeasRequest): Promise<DomainIdeasResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_IDEA_OVERVIEW}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getBestDomainPages(params: DomainIdeasRequest): Promise<DomainIdeasResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_BEST_DOMAIN_PAGES}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getTopCountriesByKeyword(params: KeywordSuggestionsRequest): Promise<KeywordMetricsDto> {
    const { keywords, country, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_DOMAINS_GET_TOP_COUNTRIES_BY_KEYWORDS}`;
    const postData = { ...data, keywords, country };

    const response = await this.post(url, { ...postData, keywords }, accessToken);
    return response.data;
  }

  async getTopCountriesByTraffic(params: KeywordSuggestionsRequest): Promise<KeywordMetricsDto> {
    const { keywords, country, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_DOMAINS_GET_TOP_COUNTRIES_BY_TRAFFIC}`;
    const postData = { ...data, keywords, country };

    const response = await this.post(url, { ...postData, keywords }, accessToken);
    return response.data;
  }


}

export const domainService = new DomainService();
