import { Grid2 } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'

import { CompetitorsModel } from './CompetitorsModel'
import { CompetitorsController } from './CompetitorsController'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_DOMAIN_COMPETITORS_KEYWORDS } from '../../../utils/TableColumnsConstants'
import Filter from '../../../components/Filter/Filter'
import { FILTER_URLS, FILTER_VOLUME } from '../../../utils/Constants'

export const CompetitorsView = () => {
  const { t } = useTranslation()

  const model = CompetitorsModel()
  const controller = CompetitorsController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
          <div className='flex gap-2 w-full pt-3'>
            <Filter name='urls' items={FILTER_URLS} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='trafficCost' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='traffic' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='keywordsCount' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <TableComponent
            rows={model.competitorsData?.items}
            rowCount={model.competitorsData?.total || 0}
            loading={model.isLoading.competitors}
            columns={COLUMNS_DOMAIN_COMPETITORS_KEYWORDS(t)}
            paginationMode='client'
            sortingMode='client'
            className='fullHeight'
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
