import { t } from 'i18next';
import Swal from 'sweetalert2';

import {
  SERVICE_KEYWORDS_GET_IN_BULK,
  SERVICE_KEYWORDS_GET_KEYWORDS,
  SERVICE_KEYWORDS_GET_NICHE_IDEAS,
  SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS,
  SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS,
  SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW,
  SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS,
  SERVICE_KEYWORDS_TRACE_LOG,
  SERVICE_KEYWORDS_SUPPORT, SERVICE_KEYWORDS_GET_TOP_COUNTRIES
} from './Endpoint'

import BaseService from './BaseService';
import { getBaseUrl } from '../utils/Utils';
import { SweetAlertIcon } from '../utils/Constants';

import KeywordDto from './dtos/keyword.dto';
import KeywordMetricsDto from './dtos/keywordsMetrics.dto';
import KeywordOverviewDto from './dtos/keywordsOverview.dto';
import { KeywordRequest, KeywordsNicheRequest, KeywordSuggestionsRequest } from '../types';
import apiClient from '../lib/apiClient';

class KeywordsService extends BaseService {
  async getKeywords(params: KeywordSuggestionsRequest): Promise<KeywordDto> {
    const { keywords, country, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_GET_KEYWORDS}`;
    const postData = { ...data, keywords, country };

    const response = await this.post(url, { ...postData, keywords }, accessToken);
    return new KeywordDto(response.data);
  }

  async getKeywordsBulk(params: KeywordSuggestionsRequest): Promise<KeywordMetricsDto> {
    const { keywords, country, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_GET_IN_BULK}`;
    const postData = { ...data, keywords, country };

    const response = await this.post(url, { ...postData, keywords }, accessToken);
    return new KeywordMetricsDto(response.data);
  }

  async getKeywordOverview(params: KeywordRequest): Promise<KeywordOverviewDto> {
    const { keywords, country, accessToken } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW}`;
    const data = { keywords, country };

    const response = await this.post(url, data, accessToken);
    return new KeywordOverviewDto(response.data);
  }

  async getNicheIdeas(params: KeywordsNicheRequest): Promise<KeywordMetricsDto> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_GET_NICHE_IDEAS}`;

    const response = await this.post(url, data, accessToken);
    return new KeywordMetricsDto(response.data);
  }

  async getExportKeywordsIdeas(params: KeywordSuggestionsRequest): Promise<string> {
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS}`;
    const { keywords, country, accessToken } = params;
    const postData = { keywords, country };

    const response = await this.post(url, postData, accessToken);
    return response.data as string;
  }

  async getExportNicheIdeas(params: KeywordSuggestionsRequest): Promise<string> {
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS}`;
    const { keywords, country, accessToken } = params;
    const postData = { keywords, country };

    const response = await this.post(url, postData, accessToken);
    return response.data as string;
  }

  async getExportBulkKeywords(params: KeywordSuggestionsRequest): Promise<string> {
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS}`;
    const { keywords, country, accessToken } = params;
    const postData = { keywords, country };

    const response = await this.post(url, postData, accessToken);
    return response.data as string;
  }

  async traceLog(params: any): Promise<any> {
    const { id, error, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_TRACE_LOG}`;
    const postData = { ...data, id, error };

    const response = await this.post(url, { ...postData, id, error }, accessToken);
    return new response.data;
  }

  async support(params: any): Promise<any> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_SUPPORT}`;

    const response = await this.post(url, data.body, accessToken);
    return response.status;
  }

  async getTopCountriesByKeyword(params: KeywordSuggestionsRequest): Promise<KeywordMetricsDto> {
    const { keywords, country, accessToken, ...data } = params;
    const url = `${getBaseUrl()}${SERVICE_KEYWORDS_GET_TOP_COUNTRIES}`;
    const postData = { ...data, keywords, country };

    const response = await this.post(url, { ...postData, keywords }, accessToken);
    return response.data;
  }

}

export const keywordsService = new KeywordsService();
