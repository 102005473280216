import { useTranslation } from 'react-i18next'
import { Grid2, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'
import KeywordMetadata from '../../../components/KeywordMetadata'
import ButtonExportExcel from '../../../components/Buttons/ExportExcel'
import KeywordBatchModel from './KeywordBatchModel'
import KeywordBatchController from './KeywordBatchController'
import Filter from '../../../components/Filter/Filter'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_KEYWORD_BATCH } from '../../../utils/TableColumnsConstants'
import { FILTER_INTENTION, FILTER_KEYWORD_DIFFICULTY, FILTER_VOLUME } from '../../../utils/Constants'

export const BatchAnalysisPage = () => {
  const { t } = useTranslation()

  const model = KeywordBatchModel()
  const controller = KeywordBatchController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='flex-col gap-2 boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_keyword_list')}
            loading={model.isLoading.search}
            onSearch={controller.handleSearchBtn}
            onCountry={controller.handleCountryUpdate}
            lines={4}
          />
          <div className='flex gap-2 w-full pt-3'>
            <Filter name='volume' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='cpc' items={[]} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='kd' items={FILTER_KEYWORD_DIFFICULTY} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='intention' items={FILTER_INTENTION} custom={false} onChange={controller.handleFilterUpdate} />
          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <div className='flex flex-col gap-2'>
            <CustomTooltip content={t('tooltips.keywords.batch_analysis_title')}>
              <Typography>{t('routes.batch-analysis')}</Typography>
            </CustomTooltip>
            <div className='flex items-center justify-between'>
              <KeywordMetadata metadata={model.keywordsData} />
              <ButtonExportExcel
                isLoading={model.isLoading.export}
                onClick={controller.handleExportBtn}
                totalKeywords={model.keywordsData?.items.length || 0}
              />
            </div>
          </div>
          <TableComponent
            rows={model.keywordsData?.items}
            loading={model.isLoading.keywords}
            columns={COLUMNS_KEYWORD_BATCH(t, { code: location })}
            paginationMode='client'
            sortingMode='client'
          />
        </PaperItem>
      </Grid2>
    </Grid2>
  )
}
