import { useMemo, useState } from 'react';
import usePaginationModel from '../../../hooks/usePaginationModel';
import useSortModel from '../../../hooks/useSortModel';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { Keyword, KeywordResponse } from '../../../types';
import { useSearchContext } from '../../../context/SearchCacheContext';

export const KeywordIdeasModel = () => {
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, keywords: false });
  const{country, setCountry} = useSearchContext()
  const[keywordsData, setKeywordsData] = useState<KeywordResponse>()
  const[filter, setFilter] = useState(new Map())

  // Pagination and Sorting in server side
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const { sortModel, setSortModel } = useSortModel()
  const [ modeFilter, setModeFilter ] = useState('related');

  return {
    isLoading, setIsLoading,
    country, setCountry,
    keywordsData, setKeywordsData,
    filter, setFilter,
    paginationModel, setPaginationModel,
    sortModel, setSortModel,
    modeFilter, setModeFilter
  }
}

export default KeywordIdeasModel