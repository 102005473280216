import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import StraightIcon from '@mui/icons-material/Straight';
import Cost from '../Cost';

interface Props {
  low_top?: number;
  high_top?: number;
}

const KeywordPositionLevel = ({ low_top, high_top }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="level-position flex gap-1">
      <Tooltip arrow followCursor title={<div><Cost value={high_top}/>: {t('tooltip.keyword_info.cpc.top.high')}</div>}>
        <div className="flex items-center">
          <Typography><Cost value={high_top} /></Typography>
          <StraightIcon />
        </div>
      </Tooltip>

      <Tooltip arrow followCursor title={<div><Cost value={low_top}/>: {t('tooltip.keyword_info.cpc.top.low')}</div>}>
        <div className="flex items-center">
          <Typography><Cost value={low_top} /></Typography>
          <SouthIcon />
        </div>
      </Tooltip>

    </div>
  )
}

export default KeywordPositionLevel;
