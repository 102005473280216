import { useTranslation } from 'react-i18next';
import './TendencyChart.scss';

import { HistoryTrendResponse } from '../../types';
import { formatNumber, formatStringDate, generateUuid, getNamePropertiesFromObjectArray } from '../../utils/Utils';

import { Skeleton } from '@mui/material';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { FC } from 'react';

interface Props {
  data?: HistoryTrendResponse[];
}

const MIN_LENGTH = 12;

const getChangePercent = (data: HistoryTrendResponse[], item: any, dataPropertiesNames: any) => {
  const index = data.findIndex((x) => x.date === item.payload.date);
  if (!index || index == -1 || index < 1 || index === data.length) return '';

  const valueBeforeColumn = data[index - 1][dataPropertiesNames[2]];
  const value = item.payload[dataPropertiesNames[2]];
  const percent = (value * 100) / valueBeforeColumn - 100;
  const sign = percent > 0 ? '+' : '';
  const clsName = percent === 0 ? 'default' : percent > 0 ? 'positive' : 'negative';
  return (
    <p className={`percent ${clsName}`}>
      {sign}
      {percent.toFixed(0)}%
    </p>
  );
};

const TendencyChart: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const aXisClassName = data?'data':'noData'
  if(!data){
    data = [
      { "date": "202312", "volume": 1 },
      { "date": "202401", "volume": 1 },
      { "date": "202402", "volume": 1 },
      { "date": "202403", "volume": 1 },
      { "date": "202404", "volume": 1 },
      { "date": "202405", "volume": 1 },
      { "date": "202406", "volume": 1 },
      { "date": "202407", "volume": 1 },
      { "date": "202408", "volume": 1 },
      { "date": "202409", "volume": 1 },
      { "date": "202410", "volume": 1 },
      { "date": "202411", "volume": 1 }
    ]
  }

  // fill="rgb(126 58 242)"

  const dataPropertiesNames = getNamePropertiesFromObjectArray({ data });
  return (
    <div className="tendencyChart flex container-chart">
      <ResponsiveContainer
        minHeight="100%"
        width="100%"
        height={270}
        className="flex container-chart-responsive">
        <BarChart data={data as any[]} className="bar-chart">
          <Bar dataKey={dataPropertiesNames[2]} className={aXisClassName} radius={[10, 10, 0, 0]} name={t('headerName.keyword_info.search_volume')} />
          <XAxis dataKey={dataPropertiesNames[1]} tick={false} axisLine={false}  />
          <YAxis tick={false} axisLine={false} width={0} />
          <Tooltip
            cursor={false}
            wrapperStyle={{ outline: 'none' }}
            labelFormatter={() => ''}
            formatter={function(value:number, a, item) {
              const percent = getChangePercent(
                data as HistoryTrendResponse[],
                item,
                dataPropertiesNames
              );
              return (
                <span>
                  {formatNumber(value)}
                  {percent}
                </span>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

};

export default TendencyChart;
