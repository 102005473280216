import './CountrySelector.scss';
import { useEffect, useState, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as CONSTANTS from '../../utils/Constants';
import { classNames, urlCountryFlag } from '../../utils/Utils';
import countries, { CountryType } from '../../utils/Countries';
import { useColorModeContext } from '../../context/ColorModeContext';

import { Box, List, Popover, ListItem, Typography } from '@mui/material';
import SearchSimple from '../SearchSimple/SearchSimple';
import { useSearchParams } from 'react-router-dom';
import { useSearchContext } from '../../context/SearchCacheContext';

interface Props {
  only?: boolean;
  onChange?: (value: Omit<CountryType, 'label'>) => void;
  initialCountry?: string;
}

const CountryWithCodeSelector: FC<Props> = ({ only, onChange, initialCountry }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const{country, setCountry} = useSearchContext()
  //const [country, setCountry] = useState<Omit<CountryType, 'label'>>(defaultCountry);

  const { mode } = useColorModeContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleinputchange = (value) => {
    setCountry(value);
    if (onChange) {
      onChange(value);
    }
    handleClose();
  };

  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setOpen((prev) => !prev);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev.toString()); // Clone the params
      newParams.delete('country');
      return newParams;
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSearch('');
  };

  const removeAccents = (str) =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  const countriesMemoized = useMemo(() => {
    if (search) {
      return countries.filter((item) =>
        removeAccents(t(`country.${item.country}`).toLowerCase()).includes(removeAccents(search.toLowerCase())));
    }
    return countries;
  }, [search]);

  useEffect(() => {
    const db = searchParams.get('country');
    if (db) {
      setCountry(db);
    } else if (initialCountry !== undefined && initialCountry !== country) {
      const newCountry = countries.find((item) => item.country === initialCountry);
      if (newCountry) {
        const defaultCountry = {
          location: newCountry.country,
          country: newCountry.country
        };
        setCountry(newCountry.country);
      }
    }
  }, [initialCountry, country]);

  return (
    <div className="country_selector">
      <button
        onClick={handleClick}
        className={classNames('btn', only ? 'only' : '', mode)}>
        <img width="20" loading="lazy" src={urlCountryFlag(country)} alt={country} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        sx={{
          '>div~div.MuiPaper-elevation': {
            maxWidth: 230,
            width: '100%',
            borderRadius: 3,
            borderWidth: 1,
            opacity: `${open}`,
            borderColor: (theme) =>
              theme.palette.mode !== CONSTANTS.COLOR_MODE_DARK
                ? 'rgba(0, 0, 0, 0.12)'
                : '#FFFFFF10',
            boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.5)',
            cursor: 'pointer'
          }
        }}
        className={'country_selector_list'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === CONSTANTS.COLOR_MODE_DARK ? '#1f1f1f' : '#FFF',
            position: 'sticky',
            top: 0,
            zIndex: 10,
          }}>
          <SearchSimple
            onChange={setSearch}
            style={{ borderRadius: 0, border: '0', borderBottom: '1px solid #1f1f1f' }}
          />
        </Box>
        <Box sx={{ px: 2, maxHeight: 200 }} className="country_selector_list">
          <List>
            {countriesMemoized.map((option) => (
              <ListItem className="item"
                key={option.country}
                sx={{ gap: 1, alignItems: 'center', borderRadius: 2 }}
                onClick={() => handleinputchange(option)}>
                <img
                  width="20"
                  loading="lazy"
                  alt={option.country}
                  src={urlCountryFlag(option.country)}
                />
                <Typography>{t(`country.${option.country}`)}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </div>
  );
};

export default CountryWithCodeSelector;