import { useTranslation } from 'react-i18next'
import AddchartIcon from '@mui/icons-material/Addchart'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Button, ButtonGroup, CircularProgress, Tooltip } from '@mui/material'
import { useRef } from 'react'

interface Props {
  loading?: boolean;
  onClickAccount: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function SplitButton(props: Readonly<Props>) {
  const anchorRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        sx={{ border: 'none' }}
        aria-label="Button group with a nested menu">
        <Tooltip title={t('tooltip.addAccount')}>
          <Button disabled={props.loading} className="btn-manage" onClick={props.onClickAccount}>
            <PersonAddIcon />
            {props.loading ? (
              <CircularProgress size={15} sx={{ bgcolor: 'transparent', color: 'white' }} />
            ) : (
              t('account')
            )}
          </Button>
        </Tooltip>
        <Tooltip title={t('tooltip.addProject')}>
          <Button disabled={props.loading} className="btn-manage" onClick={props.onClick}>
            <AddchartIcon />
            {props.loading ? (
              <CircularProgress size={15} sx={{ bgcolor: 'transparent', color: 'white' }} />
            ) : (
              t('project')
            )}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </>
  )
}
