import './Invoice.scss'

import PaidIcon from '@mui/icons-material/Paid'
import EditNoteIcon from '@mui/icons-material/EditNote'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import BlockIcon from '@mui/icons-material/Block'
import { switchClasses, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  params: {
    billingReason: string,
    status: string
  };
}

const InvoiceStatus = (params: Props) => {
  const { t } = useTranslation()
  return (
    <Typography className={`status_${params.params.status}`}>{t(params.params.status)}</Typography>
  );
};

export default InvoiceStatus;