import { useAccessToken } from './useAccessToken';
import { keywordsService } from '../services/KeywordService';

const RestKeywordController = () => {

  const token = useAccessToken();

  interface restProps {
    params: any,
    endpoint: string
  }

  const rest = async ({ params, endpoint }:restProps) => {
    return await keywordsService[endpoint]({ ...params, accessToken: token });
  }

  return {
    rest
  }

}

export default RestKeywordController
