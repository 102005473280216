import './KeywordMetadata.scss';
import { useTranslation } from 'react-i18next';

import { KeywordsMetadata } from '../../types';
import { classNames, formatNumber } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { Typography } from '@mui/material';
import WithSkeleton from '../WithSkeleton/WithSkeleton';
import Cost from '../Cost';
import { FC } from 'react';

interface Props {
  metadata: Partial<KeywordsMetadata>;
}

const KeywordMetadata: FC<Props> = ({ metadata }) => {

  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  return (
    <div className="flex items-center justify-between">
      <div className="metadata">
        <div className={classNames('metadata-item', mode)}>
            <Typography
              className="metadata-text" >
              {t('all_keywords')}: <strong>{formatNumber(metadata?.totalKeywords || 0)}</strong>
            </Typography>
        </div>
        <div className={classNames('metadata-item', mode)}>
            <Typography
              className="metadata-text" >
              {t('total_volume')}: <strong>{formatNumber(metadata?.totalSearchVolume || 0)}</strong>
            </Typography>
        </div>
        <div className={classNames('metadata-item', mode)}>
            <Typography
              className="metadata-text" >
              {t('average_kw')}: <strong>{metadata?.avgKeywordDifficulty || 0}%</strong>
            </Typography>
        </div>
        <div className={classNames('metadata-item', mode)}>
            <Typography className="metadata-text" >
              {t('average_cpc')}: <strong><Cost value={metadata?.avgCpc || 0}/></strong>
            </Typography>
        </div>
      </div>
    </div>
  );
};

export default KeywordMetadata;