import './KeywordPosition.scss';
import SouthIcon from '@mui/icons-material/South';
import StraightIcon from '@mui/icons-material/Straight';
import { formatNumber } from '../../utils/Utils'

interface Props {
  data: {
    position: number;
    prevPos: number;
  } | null;
}

const KeywordPosition = ({ data }: Props) => {
  if (data === null || data === undefined) return null;

  const dataPrevPos = data.prevPos - data.position;
  const isNaNPrevPos = isNaN(dataPrevPos) || dataPrevPos === 0;
  const withValues = dataPrevPos > 0 ? 'up' : 'down';
  const classPosition = dataPrevPos === 0 ? 'zero' : withValues;
  const withIcons =
    classPosition === 'up' ? (
      <StraightIcon />
    ) : (
      <SouthIcon />
    );
  const showIcons = classPosition === 'zero' ? '' : withIcons;

  return (
    <div className={`keyword-position ${classPosition}`}>
      <div className="keyword-position__current">{formatNumber(data.position)}</div>
      <div className="keyword-position__icon">{!isNaNPrevPos ? showIcons : ''}</div>
      <div className="keyword-position__diff">{!isNaNPrevPos ? dataPrevPos : ''}</div>
    </div>
  );
};

export default KeywordPosition;
