import BaseService from './BaseService'
import { AccessToken, KeywordSuggestionsRequest } from '../types'
import { BillingsResponse } from '../types/common.type'
import { getBaseUrl } from '../utils/Utils'
import {
  SERVICE_CRM_DOWNLOAD_INVOICE,
  SERVICE_CRM_GET_CUSTOMER_INVOICES,
  SERVICE_KEYWORDS_GET_TOP_COUNTRIES
} from './Endpoint'
import KeywordMetricsDto from './dtos/keywordsMetrics.dto'


class CrmService extends BaseService {

  async getCustomerInvoices({ accessToken }: AccessToken): Promise<Array<BillingsResponse>> {
    const url = `${getBaseUrl()}${SERVICE_CRM_GET_CUSTOMER_INVOICES}`;
    const response = await this.post(url, {}, accessToken);
    return response.data;
  }

  async downloadInvoice(params: any): Promise<any> {
    const url = `${getBaseUrl()}${SERVICE_CRM_DOWNLOAD_INVOICE}`
    const { accessToken, ...data } = params;
    return await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify({ id: params.id })
    });
  }

}

export const crmService = new CrmService()