import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  anchor: string;
  onClick?: () => void;
  disabled?: boolean
}

const ViewMoreButton: FC<Props> = ({ anchor, children, onClick, disabled}) => {
  return (
    <Stack direction="row" spacing={2} marginTop={2}>
      <Button variant="contained" href={anchor} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Stack>
  );
};

export default ViewMoreButton;