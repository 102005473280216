import './KeywordDifficulty.scss'
import { useTranslation } from 'react-i18next';
import './KeywordDifficulty.scss';
import { Tooltip, Typography } from '@mui/material';
import { keywordDifficultyValues } from '../../utils/Utils';

interface Props {
  value: number;
}

const KeywordDifficulty: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  const { htmlClass, htmlTooltip } = keywordDifficultyValues(value, t);

  return (
    <Tooltip arrow followCursor title={
      <div>
        <div>{htmlTooltip.title}</div>
        <div>{htmlTooltip.description}</div>
      </div>
    }>
      <div className={`keywordDifficulty ${htmlClass}`}>
        <div className={htmlClass} />
        <Typography className={'value'}>{value === undefined ? '—' : value}</Typography>
      </div>
    </Tooltip>
  );
};

export default KeywordDifficulty;
