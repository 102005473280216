import React from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatNumber, generateUuid } from '../../../utils/Utils';
import { DomainRankRanges } from '../../../types/domainRankRanges.type';

const MIN_LENGTH = 5;

interface Props {
  loading: boolean;
  data: DomainRankRanges | undefined;
  height?: number;
  width?: number;
}

/**
 * BarChartTop
 * @param data - json data array
 * @param height - custom height for the chart
 * @returns
 */
export const BarChartTop = ({ data, loading, height = 400, width = 500 }: Props) => {
  const { t } = useTranslation();

  if(!data){
    data = {
      '1-3': 0,
      '4-10': 0,
      '11-20': 0,
      '21-50': 0,
      '51-100': 0
    };
  }

  const fillBarMemoize = React.useMemo(() => {
    const fillBar: any[] = [];
    if (!data) return fillBar;

    Array.from({ length: MIN_LENGTH }).forEach((_, index) => {
      const nameItems = Object.keys(data)[index];
      fillBar.push({
        name: nameItems,
        label: nameItems,
        dataKey: data[nameItems]
      });
    });

    return fillBar;
  }, [data]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const tooltipData = payload[0].payload;

      return(
        <div className="tooltip container">
          <div className={'inner'}>
            <div className={'left'}>{t('positions')}:</div>
            <div className={'right'}><span className={'domain'}>{tooltipData.name}</span></div>
          </div>
          <div className={'inner'}>
            <div className={'left'}>{t('headerName.keywords')}:</div>
            <div className={'right'}>{formatNumber(tooltipData.dataKey)}</div>
          </div>
        </div>
      )
    }

  };

  return (
    <ResponsiveContainer
      width="100%"
      height={height}
      minHeight="100%"
      className="flex container-chart-responsive">
    <BarChart width={width} height={height} data={fillBarMemoize}>
        <YAxis axisLine={false} tickFormatter={(value) => {
          return formatNumber(value).toString();
        }} />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip active={undefined} payload={undefined} />}
        />
        <XAxis dataKey="label" name="value" />
        <Bar dataKey="dataKey" name="value" fill="rgb(126 58 242)" background={{ fill: '#eee' }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartTop;
