import { useTranslation } from 'react-i18next'
import { Grid2, Typography } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import * as CONSTANTS from '../../../utils/Constants'
import { FILTER_INTENTION, FILTER_KEYWORD_DIFFICULTY, FILTER_VOLUME } from '../../../utils/Constants'
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'
import KeywordMetadata from '../../../components/KeywordMetadata'
import ButtonExportExcel from '../../../components/Buttons/ExportExcel'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_KEYWORD_BATCH } from '../../../utils/TableColumnsConstants'
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup'
import Filter from '../../../components/Filter/Filter'
import KeywordIdeasController from './KeywordIdeasController'
import KeywordIdeasModel from './KeywordIdeasModel'

export const KeywordIdeasView = () => {
  const { t } = useTranslation()

  const model = KeywordIdeasModel()
  const controller = KeywordIdeasController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='flex-col gap-2 boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_keyword')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
          />
          <div className='flex gap-2 w-full pt-3'>
            { /*<KeywordsFilters />*/}

            <Filter name='volume' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='cpc' items={[]} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='kd' items={FILTER_KEYWORD_DIFFICULTY} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='intention' items={FILTER_INTENTION} custom={false} onChange={controller.handleFilterUpdate} />

          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <div className='flex flex-col gap-2'>
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('keyword_ideas')}</Typography>
            </CustomTooltip>
            <div className='flex items-center justify-between'>
              <KeywordMetadata metadata={model.keywordsData} />
              <ButtonExportExcel isLoading={model.isLoading.export} onClick={controller.handleExportBtn} totalKeywords={model.keywordsData?.totalKeywords} />
            </div>
          </div>
          <TableComponent className='fullHeight'
            rows={model.keywordsData?.items}
            rowCount={model.keywordsData?.totalKeywords || 0}
            columns={COLUMNS_KEYWORD_BATCH(t, {
              country: model.country
            })}
            loading={model.isLoading.keywords}
            paginationModel={model.paginationModel}
            onPaginationModelChange={model.setPaginationModel}
            sortModel={model.sortModel}
            onSortModelChange={model.setSortModel}
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            sortingMode='server'
            slots={{
              panel: () => (
                <div className='py-2'>
                  <ButtonGroup current={model.modeFilter} onChange={model.setModeFilter} suggestions={CONSTANTS.ALL_FILTER_MODE_IDEAS} />
                </div>
              )
            }}
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
