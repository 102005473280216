import { Grid2 } from '@mui/material'
import PaperItem from '../../../components/Essential/PaperItem'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'
import { DomainBatchModel } from './DomainBatchModel'
import { DomainBatchController } from './DomainBatchController'
import Filter from '../../../components/Filter/Filter'
import TableComponent from '../../../components/TableComponent'
import { COLUMNS_DOMAIN_BULK_ANALYSIS } from '../../../utils/TableColumnsConstants'
import { FILTER_URLS, FILTER_VOLUME } from '../../../utils/Constants'

export const DomainBatchView = () => {
  const { t } = useTranslation()

  const model = DomainBatchModel()
  const controller = DomainBatchController(model)

  return (
    <Grid2 container spacing={2} className='container-analysis'>
      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='flex-col gap-2 boxShadow' sx={{ p: 3 }}>
          <Search
            placeholder={t('search_placeholder_domain_list')}
            loading={model.isLoading.search}
            onSearch={() => controller.handleSearchBtn(false)}
            onCountry={controller.handleCountryUpdate}
            lines={4}
          />
          <div className='flex gap-2 w-full pt-3'>
            <Filter name='urls' items={FILTER_URLS} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='trafficCost' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='traffic' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
            <Filter name='keywordsCount' items={FILTER_VOLUME} custom={true} onChange={controller.handleFilterUpdate} />
          </div>
        </PaperItem>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <PaperItem className='boxShadow' sx={{ p: 3 }}>
          <TableComponent
            rows={model.bulkKeywordsData?.items}
            rowCount={model.bulkKeywordsData?.totalKeywords || 0}
            loading={model.isLoading.bulkKeywords}
            columns={COLUMNS_DOMAIN_BULK_ANALYSIS(t)}
            rowHeight={100}
            paginationMode='client'
            sortingMode='client'
            hideFooter
            className='fullHeight'
          />
        </PaperItem>
      </Grid2>

    </Grid2>
  )
}
