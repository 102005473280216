import { Grid2, LinearProgress } from '@mui/material'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'

const BackLinkType = ({ data, loading }) => {
  if (!data) {
    data = {  }
  }

  const total = Object.values(data).reduce((sum:number, value:number) => sum + value, 0)

  const chartData = Object.entries(data).map(([key, value]) => ({
    id: key,
    value,
    label: key,
    percent: total > 0 ? ((value / total) * 100).toFixed(1) : '0.0'
  }))

  return (
    <>
      {loading && <LinearProgress />}
      <Grid2 container direction='column' alignItems='center'>
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 0,
              arcLabel: (item) => `${item.percent}%`
            },
          ]}
          slotProps={{
            legend: { position: { vertical: 'middle', horizontal: 'left' } }
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: 18
            },
          }}
          margin= {{
            left: 180
          }}
          height={200}
          loading: true
        />
      </Grid2>
    </>
  )
}

export default BackLinkType
