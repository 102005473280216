import { useState } from 'react';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { KeywordResponse } from '../../../types';

export const CompetitorsModel = () => {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, competitors: false });
  const[country, setCountry] = useState<string>(COUNTRY_CODE_ES)
  const[competitorsData, setCompetitorsData] = useState<KeywordResponse>()
  const[filter, setFilter] = useState(new Map())

  return {
    isLoading, setIsLoading,
    country, setCountry,
    competitorsData, setCompetitorsData,
    filter, setFilter
  }
}