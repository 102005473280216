import { useState } from 'react';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import { KeywordResponse } from '../../../types';
import { BackLinkInterface } from '../../../interfaces/BacklinkInterface'

export const BacklinksModel = () => {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, backlinks: false });
  const[country, setCountry] = useState<string>(COUNTRY_CODE_ES)
  const[backLinksData, setBackLinksData] = useState<BackLinkInterface>()

  return {
    isLoading, setIsLoading,
    country, setCountry,
    backLinksData, setBackLinksData
  }
}