import RestDomainController from '../../../hooks/RestDomainController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useCallback, useEffect } from 'react';
import useExportData from '../../../hooks/useExportData';
import { useTranslation } from 'react-i18next';

const PositionsController = (model) => {
  const { t } = useTranslation();

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country } = useSearchContext();


  useEffect(()=> {
    if(!model.keywordsData){
      return
    }
    handleSearchBtn(null)
  }, [model.sortModel, model.paginationModel, model.modeFilter])

  // functions

  // Applu params when any of the filter changes
  const handleFilterUpdate = (name, from, to, action) => {
    model.setFilter((prevItems) => {
      const newItems = new Map([...prevItems]);

      // Initialize an object to store filter values directly
      const payload = {};

      if (action === "delete") {
        // Remove the filter keys from the Map
        newItems.delete(`${name}From`);
        newItems.delete(`${name}To`);
        newItems.delete(`${name}`);
      } else if (name === 'intention') {
        newItems.set(`${name}`, action);
      } else {
        if (from) newItems.set(`${name}From`, from);
        if (to) newItems.set(`${name}To`, to);
      }

      // Convert the Map into an object with only raw values
      newItems.forEach((value, key:any) => {
        payload[key] = value; // Store only the value, no extra object
      });

      // Update model state (if needed)
      model.setFilter(newItems);

      return new Map(newItems); // Ensure React detects changes
    });
  };


  // perform the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { domain: inputValue, country: country, mode: 'kws' };

    params['page'] = model.paginationModel.page
    params['pageSize'] = model.paginationModel.pageSize
    params['mode'] = model.modeFilter

    if(model.sortModel.length===1){
      params['field'] = model.sortModel[0].field
      params['sort'] = model.sortModel[0].sort
    }

    model.filter.forEach((value, key) => {
      params[key] = value;
    });

    model.setIsLoading((prev) => ({ ...prev, search: true }))
    model.setIsLoading((prev) => ({ ...prev, keywords: true }))
    rest({ params, endpoint: 'getKeywords' })
      .then((data) => {
        model.setKeywordsData(data.keywords);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, keywords: false }))
        model.setIsLoading((prev) => ({ ...prev, search: false }))
      })

  }, [inputValue, country, model.filter, model.sortModel, model.paginationModel, model.modeFilter]);


  // Export to excel
  const { isLoading, fetchExportDomainKeywords} = useExportData();
  const handleExportBtn = async () => {
    model.setIsLoading({ export: true })
    const params = { domain: inputValue, country: country };
    await fetchExportDomainKeywords({ ...params, title: t('exportDomainsPositions') });
    model.setIsLoading({ export: false })
  }


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }

  return {
    handleSearchBtn,
    handleCountryUpdate,
    handleFilterUpdate,
    handleExportBtn
  }
}

export default PositionsController