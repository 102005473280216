import { useMemo, useState } from 'react';
import { Keyword, KeywordResponse } from '../../../types';
import { COUNTRY_CODE_ES } from '../../../utils/Constants';
import usePaginationModel from '../../../hooks/usePaginationModel';
import { useKeywordsIdeasCache } from '../../../context/KeywordsIdeasCacheContext';
import useSortModel from '../../../hooks/useSortModel';
import { useSearchContext } from '../../../context/SearchCacheContext';

const KeywordExplorerModel = () => {

  const[isLoading, setIsLoading] = useState<Record<string, boolean>>({ search: false, export: false, keywords: false, keywordOverview: false, topCountries: false });
  const{country, setCountry} = useSearchContext()
  const[keywordsOverviewData, setKeywordsOverviewData] = useState<KeywordResponse>()
  const[keywordsData, setKeywordsData] = useState<KeywordResponse>()
  const[topCountries, setTopCountries] = useState<number[]>([])
  const[metrics, setMetrics] = useState<Keyword<any>>()
  const dataLevelCompetition = useMemo(() => ({
    cpc: metrics?.keyword_info?.cpc,
    intent_info: metrics?.search_intent_info,
    competition: metrics?.keyword_info?.competition,
    time_update: metrics?.keyword_info?.time_update,
    low_top: metrics?.keyword_info?.low_top_of_page_bid,
    high_top: metrics?.keyword_info?.high_top_of_page_bid,
  }), [metrics]);
  const resultsLevelCompetition = useMemo(() => ({
    item_types: metrics?.serp_info?.item_types || [],
    se_results_count: metrics?.serp_info?.se_results_count,
  }), [metrics]);

  return {
    isLoading, setIsLoading,
    country, setCountry,
    keywordsOverviewData, setKeywordsOverviewData,
    keywordsData, setKeywordsData,
    topCountries, setTopCountries,
    metrics, setMetrics,
    dataLevelCompetition,
    resultsLevelCompetition
  }
}

export default KeywordExplorerModel