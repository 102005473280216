
import { useTranslation } from 'react-i18next';
import * as CONSTANTS from '../../utils/TableColumnsConstants';
import { DomainSearchIntents } from '../../types/domainSearchIntents.type';

import { Grid2, Skeleton } from '@mui/material';
import TableComponent from '../TableComponent';
import ProgressBarMultiple from './ProgressBarMultiple';
import { getPercentage } from '../../utils/Utils';
import { FC } from 'react';

interface Props {
  loading: boolean
  intentsData?: any
}


const KeywordByIntents: FC<Props> = ({ loading, intentsData }) => {
  const { t } = useTranslation();

  let dataFetched: boolean = false

  if(!intentsData){
    intentsData = {
      "commercial": 0,
      "navigational": 0,
      "informational": 0,
      "transactional": 0
    }
  } else {
    dataFetched = true
  }

  const totalKeywords = Object.values(intentsData).reduce((acc, curr) => acc + curr, 0);
  const rows = Object.entries(intentsData)
    .map(([key, value], index) => ({
      key: index,
      name: key,
      urls: value,
      value,
      distribution: getPercentage(value, totalKeywords),
      className: key,
      search_intent_info: {
        main_intent: key
      }
    }))
    .flat();

  return (
    <Grid2 container spacing={0} height="100%"  >
      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <ProgressBarMultiple data={rows} dataFetched={dataFetched} />
      </Grid2>
      <Grid2 size={{ xs:12, sm:12, md:12, lg:12, xl:12 }} >
        <TableComponent
          hideFooter
          rows={rows}
          loading={loading}
          columns={CONSTANTS.COLUMNS_DOMAIN_KEYWORD_INTENT(t)}
          sortingMode="client"
        />
      </Grid2>
    </Grid2>
  )

};

export default KeywordByIntents;
