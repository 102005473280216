const Splash = () => {
  return (
    <div className='three-container'>
      <div className='three-body'>
        <div className='three-body__dot' />
        <div className='three-body__dot' />
        <div className='three-body__dot' />
      </div>
    </div>
  )
}

export default Splash
