import './Chart.scss';
import { useTranslation } from 'react-i18next';
import ChartModel from './ChartModel';
import ChartController from './ChartController';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { extractRootDomain, formatNumber, isMobile } from '../../../../utils/Utils';
import { Fragment, useEffect, useMemo } from 'react';
import CustomDot from '../CustomDot/CustomDot';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { IconButton, useMediaQuery } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import dayjs from "dayjs/dayjs.min"
import { FORMAT_DATE_DAYJS } from '../../../../utils/Constants';

const ChartView = ({
                     searchData = [],
                     metrics,
                     googleUpdates = [],
                     isGeneralView,
                     isCustomDomain,
                     hiddenLines,
                     showCustomDots,
                     modelProject,
                     showLegend
                   }) => {
  const { t } = useTranslation();

  const model = ChartModel();
  const controller = ChartController(model);

  const isMobile = useMediaQuery('(max-width: 767px)')

  /**
   * Update general domains in chart
   */
  useEffect(() => {
    if (isCustomDomain) {
      if (model.showFavoritesInGeneral && modelProject.favoriteProjects.size > 0) {
        model.setDomainsGeneralChart(Array.from(modelProject.favoriteProjects).map(item => extractRootDomain(item)));
      } else {
        model.setDomainsGeneralChart([...new Set(searchData.flatMap(entry => Object.keys(entry).filter(key => key !== 'date')))]);
      }
    }
  }, [modelProject?.favoriteProjects, model.showFavoritesInGeneral, searchData]);

  const activeMetrics = Object.keys(metrics).filter(metric => metrics[metric]);
  const minMaxValues = controller.getMinMaxValues(searchData);
  const hideYAxis = isMobile || activeMetrics.length > 2;


  const areaConfig = useMemo(() => (
    isCustomDomain ? model.domainsGeneralChart : ['impressions', 'clicks', 'ctr', 'position']
  ).map((key, index) => {
    const className = isCustomDomain ? 'general': `metric_${key}`
    const shouldRender = !(hiddenLines && hiddenLines[key]) && !model.localHiddenLines[key];
    return (
      <Fragment key={index}>
        <Area key={`area_${key}`} type="monotone" yAxisId={isCustomDomain ? 'custom' : key} dataKey={key}
              hide={!shouldRender} className={className}
              stroke={isCustomDomain?model.colorArray[Number(String(index).slice(-2))]:undefined}
              fill={isCustomDomain?model.colorArray[Number(String(index).slice(-2))]:undefined}
              fillOpacity={0.1}
        />
        {isCustomDomain ? (
          <YAxis key={`yaxis1_${key}`} yAxisId={isCustomDomain ? 'custom' : key} domain={[0, 100]} hide={isMobile}
                 axisLine={false} tickLine={false} className={`metric_impressions`}
                 tickFormatter={(value) => formatNumber(value).toString()} tick={<controller.customXAxisTick />} />
        ) : (
          <YAxis key={`yaxis2_${key}`} yAxisId={isCustomDomain ? 'custom' : key}
                 domain={[minMaxValues[key].min, minMaxValues[key].max]}
                 hide={hideYAxis || !metrics[key]} orientation={activeMetrics[0] === key ? 'left' : 'right'}
                 tickFormatter={(value) => formatNumber(value, 0).toString()}
                 className={`metric_${key}`}
                 axisLine={false}
                 tickLine={false}
          />
        )}
      </Fragment>
    );
  }), [isCustomDomain, model.domainsGeneralChart, hiddenLines, model.localHiddenLines]);

  const marginLeft = isCustomDomain ? -15 : -15;
  const marginRight = isCustomDomain ? -10 : -25;

  return (
    <div>
      <ResponsiveContainer width="100%" height={200} className="chart" >
        <AreaChart data={searchData} margin={{ top: 10, right: marginRight, left: marginLeft }}>
          <XAxis
            dataKey="date"
            {...(isGeneralView && { tick: false, axisLine: false, tickLine: false })}
            hide={isMobile}
            axisLine={false}
            tickLine={false}
            allowDataOverflow={false}
          />

          {areaConfig}

          <YAxis yAxisId="hidden" hide />

          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <RechartsTooltip content={<CustomTooltip isCustomDomain={isCustomDomain} blurTitle={model.blur} />} />

          {showCustomDots && googleUpdates.map((update, index) => (
            <Fragment key={index}>
              <ReferenceDot
                x={dayjs(update.created).format(FORMAT_DATE_DAYJS)}
                y={isMobile ? 8 : 0}
                yAxisId="hidden"
                ifOverflow="extendDomain"
                r={0}
                shape={<CustomDot update={update} activeDotId={model.activeDotId}
                                  setActiveDotId={model.setActiveDotId} />}
              />
              <ReferenceLine
                x={dayjs(update.created).format(FORMAT_DATE_DAYJS)}
                yAxisId="hidden"
                stroke="#FFA500"
                strokeDasharray="3 3"
              />
            </Fragment>
          ))}

          {showLegend && !isMobile && (
            <Legend
              verticalAlign="top"
              height={36}
              onClick={controller.handleLegendClickLocal}
              formatter={(value, entry: any) => (
                <span style={{
                  opacity: model.localHiddenLines[entry.dataKey] ? 0.5 : 1,
                  filter: model.blur && isCustomDomain ? 'blur(4px)' : 'none'
                }}>{value}</span>
              )}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>

      {isCustomDomain &&
        <IconButton onClick={controller.toggleFavoriteArea} style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer', opacity: modelProject.favoriteProjects.size===0?0:1}} >
          <Tooltip arrow followCursor title={t('showFavorites')}>
              {model.showFavoritesInGeneral ? <Star sx={{color: "gold"}} /> : <StarBorder />}
          </Tooltip>
        </IconButton>
      }


    </div>
  );
};

export default ChartView;