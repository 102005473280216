import './styles.scss';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../../utils/Constants';
import { classNames, formatNumber } from '../../../utils/Utils';
import { useColorModeContext } from '../../../context/ColorModeContext';

import { Grid2, Typography } from '@mui/material';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';
import Cost from '../../Cost';
import { useSearchContext } from '../../../context/SearchCacheContext';

interface Props {
  data: {
    totalKeywords: number,
    traffic: number,
    trafficCost: number,
    urls: number
  }
}

const DomainMetadata: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const { mode } = useColorModeContext();
  const { inputValue, country } = useSearchContext();

  const linkToDomainKeyword = `${ROUTES.DOMAIN_KEYWORDS}?q=${inputValue}&country=${country}`;
  return (
    <>
      <Grid2 container className={classNames('metadata', mode)} sx={{ height: 'inherit' }} >

        <Grid2 container size={{ xs:5 }} flexDirection='column' justifyContent='space-between' >

          <Grid2 className="item" >
            <CustomTooltip content={t('tooltips.domains.overview_traffic')}>
              <Typography className="title">
                {t('traffic')}
              </Typography>
            </CustomTooltip>
            <Typography className={data?.traffic && 'data'} mt={-1} >
              {formatNumber(data?.traffic)}
            </Typography>
          </Grid2>

          <Grid2 className="item" >
            <CustomTooltip content={t('tooltip.keywords')}>
              <Typography className="title">
                {t('total_keywords')}
              </Typography>
            </CustomTooltip>
              <Typography className={data?.traffic && 'data'} mt={-1} >
                <Link to={linkToDomainKeyword} >
                  {formatNumber(data?.totalKeywords as number)}
                </Link>
              </Typography>
          </Grid2>


        </Grid2>

        <Grid2 container size={{ xs:7 }} flexDirection='column' justifyContent='space-between'  >

          <Grid2 className="item" >
            <CustomTooltip content={t('tooltips.domains.overview_traffic_price')}>
              <Typography className="title">
                {t('price_traffic')}
              </Typography>
            </CustomTooltip>
            <Typography className={data?.traffic && 'data'} mt={-1} >
              <Cost value={data?.trafficCost} />
            </Typography>
          </Grid2>

          <Grid2 className="item" >
            <CustomTooltip content={t('tooltip.urls')}>
              <Typography className="title">
                {t('urls')}
              </Typography>
            </CustomTooltip>
            <Typography className={data?.traffic && 'data'} mt={-1}  >
              {formatNumber(data?.urls as number)}
            </Typography>
          </Grid2>

        </Grid2>
      </Grid2>
    </>
      /*
    <Grid2 container className={classNames('metadata', mode)} sx={{ border: '1px solid red;' }} >

      <Grid2 container sx={{ border: '1px solid blue;' }} >
        <Grid2 sx={{ border: '1px solid green;' }} >
          <div>1</div>
        </Grid2>
        <Grid2 sx={{ border: '1px solid green;' }} >
          <div>2</div>
        </Grid2>
      </Grid2>

      <Grid2 container sx={{ border: '1px solid blue;' }} >
        <Grid2 sx={{ border: '1px solid green;' }} >
          <div>3</div>
        </Grid2>
        <Grid2 sx={{ border: '1px solid green;' }} >
          <div>4</div>
        </Grid2>
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:6, lg:3, xl:3 }} className="item">
        <CustomTooltip content={t('tooltips.domains.overview_traffic')}>
          <Typography className="title">
            {t('traffic')}
          </Typography>
        </CustomTooltip>
          <Typography className={data?.traffic && 'data'} mt={-1} >
              {formatNumber(data?.traffic)}
          </Typography>
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:6, lg:3, xl:3 }} className="item" >
        <CustomTooltip content={t('tooltips.domains.overview_traffic_price')}>
          <Typography className="title">
            {t('price_traffic')}
          </Typography>
        </CustomTooltip>
          <Typography className={data?.traffic && 'data'} mt={-1} >
              <Cost value={data?.trafficCost} />
          </Typography>
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:6, lg:3, xl:3 }} className="item" >
        <CustomTooltip content={t('tooltip.keywords')}>
          <Typography className="title">
            {t('total_keywords')}
          </Typography>
        </CustomTooltip>
            <Link to={linkToDomainKeyword} >
              <Typography className={data?.traffic && 'data'} mt={-1} >
                {formatNumber(data?.totalKeywords as number)}
              </Typography>
            </Link>
            <Link to={linkToDomainKeyword} />
      </Grid2>

      <Grid2 size={{ xs:12, sm:12, md:6, lg:3, xl:3 }} className="item" >
        <CustomTooltip content={t('tooltip.urls')}>
          <Typography className="title">
            {t('urls')}
          </Typography>
        </CustomTooltip>
          <Typography className={data?.traffic && 'data'} mt={-1}  >
              {formatNumber(data?.urls as number)}
          </Typography>
      </Grid2>
    </Grid2>
      */


  );
};

export default DomainMetadata;