import OverviewRedirectLink from '../OverviewRedirectLink/KeywordRedirectLink';
import { FC } from 'react'

interface Props {
  url: string;
  domain: string;
}

const SerpDomain: FC<Props> = ({ code, url, domain }) => {
  return (
    <OverviewRedirectLink location={code} domain={domain} url={url} />
  );
};

export default SerpDomain;
