import RestDomainController from '../../../hooks/RestDomainController';
import { useSearchContext } from '../../../context/SearchCacheContext';
import { useCallback } from 'react';
import { parseStringToArray } from '../../../utils/Utils';

export const ContentGapController = (model) => {

  // hooks
  const { rest } = RestDomainController();
  const { inputValue, country } = useSearchContext();

  // functions


  // Controls the search
  const handleSearchBtn = useCallback(async (cleanParams: boolean) => {

    const params = { domain: parseStringToArray(inputValue), country: country, limit: 10 };
    model.setDomains(parseStringToArray(inputValue))

    model.setIsLoading((prev) => ({ ...prev, search: true }))
    model.setIsLoading((prev) => ({ ...prev, keywords: true }))
    params['limit'] = 10
    rest({ params, endpoint: 'getContentGap'})
      .then((data) => {
        model.setKeywordsData(data);
      })
      .catch((error) => {
        console.error(`Error:`, error);
      })
      .finally(() => {
        model.setIsLoading((prev) => ({ ...prev, keywords: false }))
        model.setIsLoading((prev) => ({ ...prev, search: false }))
      })

  }, [inputValue, country, model.keywordsData, model.keywordsOverviewData, model.searchIntentData]);


  // Update the country
  const handleCountryUpdate = (newLocation: string) => {
    model.setCountry(newLocation)
  }

  const columnVisibilityModel = {

    ...model.domains.reduce((acc, _, index) => {
      if (model.modeFilter === 'unique') {
        acc[`domain_${index + 1}`] = model.modeFilter  !== 'unique';
        return acc;
      } else if (model.modeFilter  === 'missing') {
        acc[`domain_0`] = false;
        return acc;
      }
    }, {})

  };

  return {
    handleSearchBtn,
    handleCountryUpdate,
    columnVisibilityModel
  }

}